import {Wave} from 'better-react-spinkit';
import {isArray} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../lib/Alert';
import User from '../models/User';

class Password extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false,
            formValues: {
                password: '',
                newPassword: ''
            }
        };
    }

    onCancel = () => this.props.history.push('/');

    onChangePassword = e => this.setState({
        formValues: Object.assign(this.state.formValues, {password: e.target.value})
    });

    onChangeNewPassword = e => this.setState({
        formValues: Object.assign(this.state.formValues, {newPassword: e.target.value})
    });

    onSave = () => {
        const constraints = {
            password: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa la contraseña actual'
                }
            },
            newPassword: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa la contraseña nueva'
                },
                length: {
                    minimum: 8,
                    message: 'La contraseña nueva debe tener 8 caracteres como mínimo'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                const to = '/change-password';
                const data = {
                    oldPassword: this.state.formValues.password,
                    newPassword: this.state.formValues.newPassword
                };

                User.post(data, to).then(result => {
                    if (result.status) {
                        Alert.info('Contraseña cambiada');
                    } else {
                        Alert.error('Error. No se pudo cambiar la contraseña')
                    }
                }).finally(() => this.setState({processing: false}));
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <Button className="btn-derive" onClick={this.onSave}>CAMBIAR</Button>
        );
    };

    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CAMBIAR CONTRASEÑA</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstName">
                                                <img alt="" src="/img/svg/icon_padlock.svg" width="20"/>
                                            </Label>
                                            <Input id="password"
                                                   name="password"
                                                   onChange={this.onChangePassword}
                                                   placeholder="Contraseña actual *"
                                                   type="password"
                                                   value={this.state.formValues.password}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstName">
                                                <img alt="" src="/img/svg/icon_padlock.svg" width="20"/>
                                            </Label>
                                            <Input id="newPassword"
                                                   name="newPassword"
                                                   onChange={this.onChangeNewPassword}
                                                   placeholder="Contraseña nueva *"
                                                   type="password"
                                                   value={this.state.formValues.newPassword}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-2 pad0">
                            <div className="flex flex-btns">
                                <div>
                                    <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                                    {this.renderSubmitButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withRouter(Password);
