import {Grid, Table, TableColumnResizing, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {parallel, waterfall} from 'async';
import {Wave} from 'better-react-spinkit';
import {in_array as inArray} from 'locutus/php/array';
import {clone, isArray, isEmpty, isNil} from 'lodash';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Checkbox, CheckboxGroup} from 'react-checkbox-group';
import Dropdown from 'react-dropdown';
import {IconContext} from 'react-icons';
import {FaSave, FaTrash} from 'react-icons/fa';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../../lib/Alert';
import Constants from '../../../lib/Constants';
import SalesManager from '../../../models/SalesManager';
import Brand from '../../../models/Brand';
import Store from '../../../models/Store';
import User from '../../../models/User';

class BrandsDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brandIds: this.props.row.brandIds || []
        };
    }

    onChangeBrandIds = values => this.setState({brandIds: values}, () => this.props.onUpdate(this.props.row, this.state.brandIds));

    render() {
        return (
            <CheckboxGroup checkboxDepth={2}
                           onChange={this.onChangeBrandIds}
                           value={this.state.brandIds}
            >
                {this.props.brands.map((brand, i) =>
                    <p key={`brand-${this.props.row.id}-${i}`}>
                        <Checkbox value={brand.id}/> {brand.name}
                    </p>
                )}
            </CheckboxGroup>
        );
    }
}

class UpdateButton extends React.Component {
    onClick = e => {
        e.preventDefault();
        this.props.onUpdate(this.props.row);
    };

    render() {
        return (
            <a href="/" onClick={this.onClick}>
                <FaSave/>
            </a>
        );
    }
}

class DeleteButton extends React.Component {
    onClick = e => {
        e.preventDefault();
        this.props.onDelete(this.props.row);
    };

    render() {
        return (
            <a href="/" onClick={this.onClick}>
                <FaTrash/>
            </a>
        );
    }
}

class SalesManagers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            processing: false,
            store: {},
            salesManagers: [],
            users: [],
            formValues: {
                userId: ''
            },
            rowInfo: {},
            columns: [
                {
                    name: 'name',
                    title: 'Nombre',
                    getCellValue: row => !isNil(row.user) ? [row.user.firstName, row.user.lastName].join(' ') + (row.user.deleted ? " (Eliminado)" : '') : ''
                },
                {
                    name: 'brandNames',
                    title: 'Marcas',
                    getCellValue: row => <BrandsDropdown row={row} brands={this.state.brands} onUpdate={this.onUpdateBrandIds}/>
                },
                {
                    name: 'actions',
                    title: 'Acción',
                    getCellValue: row =>
                        <IconContext.Provider value={{color: '#E30012'}}>
                            <UpdateButton row={row} onUpdate={this.onUpdate}/>
                            <DeleteButton row={row} onDelete={this.onDelete}/>
                        </IconContext.Provider>
                }
            ],
            defaultColumnWidths: [
                {columnName: 'name', width: 240},
                {columnName: 'brandNames', width: 240},
                {columnName: 'actions', width: 180}
            ]
        };
    }

    componentDidMount() {
        this.load();
    }

    assign = user => {
        this.setState({processing: true}, () => {
            const newState = {
                processing: false
            };
            const errorMessage = 'Error';
            const tasks = [
                callback => {
                    const params = `filter[where][storeId]=${this.state.store.id}&filter[where][userId]=${user.id}`;

                    SalesManager.find(params).then(result => {
                        if (result.status) {
                            callback(null, {proceed: isEmpty(result.data), error: false});
                        } else {
                            callback(null, {proceed: false, error: true});
                        }
                    }).catch(e => {
                        console.log(e);
                        callback(e);
                    });
                },
                (checkResult, callback) => {
                    if (checkResult.error) {
                        callback(null, false);
                    } else {
                        if (checkResult.proceed) {
                            const data = {
                                storeId: this.state.store.id,
                                userId: user.id,
                                brandIds: []
                            };

                            SalesManager.create(data).then(result => {
                                if (result.status) {
                                    newState.formValues = {
                                        userId: ''
                                    };
                                    newState.salesManagers = clone(this.state.salesManagers).concat([result.data]);
                                    newState.users = this.state.users.filter(x => !inArray(x.id, [user.id]));
                                }

                                callback(null, result.status);
                            }).catch(e => callback(e));
                        } else {
                            Alert.error('El jefe ya se encuentra asignado a la tienda');
                            callback(null, false);
                        }
                    }
                }
            ];

            waterfall(tasks, (err, result) => {
                if (err || !result) {
                    Alert.error(errorMessage);
                }

                this.setState(newState, () => this.load());
            })
        });
    };

    getTasks = () => {
        const storeId = this.props.match.params.id;

        return {
            salesManagers: callback => {
                const params = `filter[where][storeId]=${storeId}&filter[include]=user&filter[include]=brands`;
                const errorMessage = 'Error cargando la lista de jefes de tienda asignados';

                SalesManager.find(params).then(result => {
                    if (result.status) {
                        callback(null, result.data);
                    } else {
                        Alert.error(errorMessage);
                        callback(null, false);
                    }
                }).catch(e => {
                    console.log(e);
                    Alert.error(errorMessage);
                    callback(e);
                });
            },
            users: callback => {
                const params = `filter[where][role]=${Constants.Role.SALES_MANAGER}&filter[where][deleted]=false`;
                const errorMessage = 'Error cargando la lista de jefes de tienda disponibles';

                User.find(params).then(result => {
                    if (result.status) {
                        callback(null, result.data);
                    } else {
                        Alert.error(errorMessage);
                        callback(null, false);
                    }
                }).catch(e => {
                    console.log(e);
                    Alert.error(errorMessage);
                    callback(e);
                });
            },
            brands: callback => {
                const errorMessage = 'Error cargando la lista de marcas';

                Brand.find().then(result => {
                    if (result.status) {
                        callback(null, result.data);
                    } else {
                        Alert.error(errorMessage);
                        callback(null, false);
                    }
                }).catch(e => {
                    console.log(e);
                    Alert.error(errorMessage);
                    callback(e);
                });
            }
        };
    };

    getUserOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.users.forEach(user => {
            options.push({value: user.id, label: [user.firstName, user.lastName].join(' ')});
        });

        return options;
    };

    load = () => {
        const storeId = this.props.match.params.id;
        let newState = {
            loading: false
        };

        Store.findById(storeId).then(result => {
            if (result.status && !result.data.deleted) {
                newState.store =  result.data;

                parallel(this.getTasks(), (err, result) => {
                    if (!err) {
                        newState = Object.assign(newState, result);
                        const userIds = newState.salesManagers.map(x => x.userId);
                        newState.users = newState.users.filter(x => !inArray(x.id, userIds));

                        this.setState(newState);
                    }
                });
            } else {
                Alert.error('Tienda inválida');
                this.props.history.goBack();
            }
        }).catch(e => {
            console.log(e);
            Alert.error('Lo sentimos, ocurrió un error');
        });
    };

    onAssign = e => {
        e.preventDefault();

        const constraints = {
            userId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona al jefe de tienda'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            const selectedUsers = this.state.users.filter(x => x.id === this.state.formValues.userId);

            if (!isEmpty(selectedUsers)) {
                const fullname = [selectedUsers[0].firstName, selectedUsers[0].lastName].join(' ');

                confirmAlert({
                    title: `Asignar a ${fullname} como jefe de tienda de ${this.state.store.name}`,
                    message: '¿Estás segur@?',
                    buttons: [
                        {
                            label: 'Sí',
                            onClick: () => this.assign(selectedUsers[0])
                        },
                        {
                            label: 'No',
                            onClick: () => {}
                        }
                    ]
                });
            } else {
                Alert.error('Error. Jefe de tienda inválido');
            }
        }
    };

    onChangeUserId = option => this.setState({formValues: Object.assign(this.state.formValues, {userId: option.value})});

    onDelete = row => {
        const errorMessage = 'Error';

        SalesManager.delete(row.id).then(result => {
            if (result.status) {
                Alert.info('Eliminado');
                this.load();
            } else {
                Alert.error(errorMessage);
            }
        }).catch(e => {
            console.log(e);
            Alert.error(errorMessage);
        });
    };

    onGoBack = () => this.props.history.goBack();

    onUpdate = row => {
        if (!isNil(this.state.rowInfo[row.id])) {
            const data = {
                brandIds: this.state.rowInfo[row.id]
            };
            const errorMessage = 'Error';

            SalesManager.update(row.id, data).then(result => {
                if (result.status) {
                    Alert.info('Actualizado');
                } else {
                    Alert.error(errorMessage);
                }
            }).catch(e => {
                console.log(e);
                Alert.error(errorMessage);
            });
        }
    };

    onUpdateBrandIds = (row, brandIds) => {
        let rowInfo = clone(this.state.rowInfo);
        rowInfo[row.id] = brandIds;

        this.setState({rowInfo: rowInfo});
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onGoBack}>REGRESAR</Button>
                <Button className="btn-derive" onClick={this.onAssign}>ASIGNAR</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">JEFES DE TIENDA</h2>
                                <div className="subline"/>
                                <h4>{this.state.store.name}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">
                                <div className="col-sm-12">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="userId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Dropdown id="userId"
                                                      name="userId"
                                                      className={"form-control form-control-dropdown"}
                                                      onChange={this.onChangeUserId}
                                                      options={this.getUserOptions()}
                                                      placeholder="Jefes de tienda disponibles *"
                                                      value={this.state.formValues.userId}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-2 pad0">
                            <div className="flex flex-btns">
                                {this.renderSubmitButton()}
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p>&nbsp;</p>
                                <Grid rows={this.state.salesManagers} columns={this.state.columns}>
                                    <Table/>
                                    <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                                    <TableHeaderRow/>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SalesManagers);
