import React from 'react';
import {withRouter} from 'react-router-dom';
import Auth from '../lib/Auth';

class Logout extends React.Component{
    componentDidMount() {
        if (Auth.isAuthenticated()) {
            Auth.logout();
        }

        this.props.history.push('/login');
    }

    render() {
        return null;
    }
}

export default withRouter(Logout);
