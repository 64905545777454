import Api from '../lib/models/Api';

class Dealership extends Api {
    static endpoint = '/dealerships';

    static changeCotiName (id, coti_name)
    {
        return this.update(id + '/cotiName',{coti_name:coti_name})
    }

    static async refreshAllCotiCodes(data) {
        return this.post(data,'/cotizador')
    }
}

export default Dealership;
