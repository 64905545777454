import {FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';
import {Grid, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {Wave} from 'better-react-spinkit';
import {isEmpty, isNil} from 'lodash';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {IconContext} from 'react-icons';
import {FaEdit, FaTrash} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import Alert from '../../../lib/Alert';
import Dealership from '../../../models/Dealership';

class DeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        };
    }

    delete = () => {
        this.setState({processing: true}, () => {
            const data = {
                enabled: false,
                deleted: true
            };
            const newState = {
                processing: false
            };
            const errorMessage = 'Error. No se pudo borrar al concesionario';

            Dealership.update(this.props.row.id, data).then(result => {
                if (result.status) {
                    this.props.onDelete(this.props.row);
                    Alert.info('Concesionario borrado');
                } else {
                    Alert.error(errorMessage);
                }
            }).catch(e => {
                console.log(e);
                Alert.error(errorMessage);
            }).finally(() => this.setState(newState));
        });
    };

    onClick = e => {
        e.preventDefault();
        confirmAlert({
            title: `Borrar concesionario ${this.props.row.name}`,
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.delete()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    render() {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <a className="pl-1" href="/" onClick={this.onClick}>
                <FaTrash/>
            </a>
        );
    }
}

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            columns: [
                {
                    name: 'actions',
                    title: 'Acción',
                    getCellValue: row =>
                        <IconContext.Provider value={{color: '#E30012'}}>
                            <Link to={`/dealerships/${row.id}/edit`}>
                                <FaEdit/>
                            </Link>
                            <DeleteButton row={row} onDelete={this.onDelete}/>
                        </IconContext.Provider>
                },
                {
                    name: 'name',
                    title: 'Nombre'
                },
                {
                    name: 'dealershipManagerName',
                    title: 'Gerente de concesionario',
                    getCellValue: row => !isNil(row.dealershipManager) ? <Link to={`/users/${row.dealershipManager.id}/edit`}>{[row.dealershipManager.firstName, row.dealershipManager.lastName].join(' ')}</Link> : ''
                },
                {
                    name: 'businessDelegateName',
                    title: 'Delegado comercial',
                    getCellValue: row => !isNil(row.businessDelegate) ? <Link to={`/users/${row.businessDelegate.id}/edit`}>{[row.businessDelegate.firstName, row.businessDelegate.lastName].join(' ')}</Link> : ''
                },
                {
                    name: 'storeNames',
                    title: 'Tiendas',
                    getCellValue: row => !isNil(row.stores) && !isEmpty(row.stores) ? row.stores.map(store => <p key={`store-${store.id}`}><Link to={`/stores/${store.id}/edit`}>{store.name}</Link></p>) : ''
                },
                {
                    name: 'statusName',
                    title: 'Estado',
                    getCellValue: row => !isNil(row.enabled) ? (row.enabled ? 'Habilitado' : 'Deshabilitado') : ''
                }
            ],
            columnExtensions: [
                {columnName: 'actions', filteringEnabled: false}
            ],
            defaultColumnWidths: [
                {columnName: 'actions', width: 180},
                {columnName: 'name', width: 300},
                {columnName: 'businessDelegateName', width: 240},
                {columnName: 'dealershipManagerName', width: 240},
                {columnName: 'storeNames', width: 240},
                {columnName: 'statusName', width: 180}
            ],
            rows: []
        };
    }

    componentDidMount() {
        const params = `filter[where][deleted]=false&filter[include]=businessDelegate&filter[include]=dealershipManager&filter[include]=stores&filter[order]=name ASC`;

        Dealership.find(params).then(result => {
            if (result.status) {
                this.setState({loading: false, rows: result.data});
            }
        });
    }

    onDelete = row => this.setState({rows: this.state.rows.filter(x => x.id !== row.id)});

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CONCESIONARIOS</h2>
                                <div className="subline"/>
                            </div>
                            <div>
                                <Link to="/dealerships/create">
                                    <img alt="" src="/img/svg/icon_mas_rojo.svg" width="25"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid rows={this.state.rows} columns={this.state.columns}>
                                    <FilteringState columnExtensions={this.state.columnExtensions}/>
                                    <IntegratedFiltering/>
                                    <Table/>
                                    <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                                    <TableHeaderRow/>
                                    <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
