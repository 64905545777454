import React, { useRef, useEffect } from "react";
import { FaTheRedYeti } from 'react-icons/fa';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from 'reactstrap';

import Alert from '../../../../../lib/Alert'


export default class EditCotiName extends React.Component 
{
  changeValue = e => {
    e.preventDefault();
    console.log(e.target.value)
    document.getElementById("newNameCoti").value = e.target.value
    document.getElementById("boton_submit").disabled = this.isChange();
    
  }

  submitValue = async(e) => {
    if (!this.isChange())
    {
      document.getElementById("boton_submit").disabled  = true;
      document.getElementById("boton_quit").disabled  = true;      
      document.getElementById("newNameCoti").disabled  = true;

      var input = document.getElementById("newNameCoti");
      document.body.style.cursor = 'wait'
      await this.props.submitChange(this.props.entity.id,input.value);
      document.body.style.cursor = 'default'
      
      setTimeout(() => {
        Alert.info("Se actualizó con éxito")
        if ( document.getElementById("boton_quit"))
        {
          document.getElementById("boton_quit").disabled  = false;
          document.getElementById("boton_quit").click()
        }
      }, 500);
    }
  }

  /**
   * verifica si el input text ha cambiado
   * @returns {boolean}
   */
  isChange()
  {
    var input = document.getElementById("newNameCoti");
    return this.props.entity.coti_name === input.value;
  }

  render()
  {
    setTimeout(() => {
      document.getElementById("newNameCoti").focus();
      document.getElementById("newNameCoti").value = this.props.entity.coti_name;
      document.getElementById("boton_submit").disabled = true;
      
      
    }, 300);
    return <Modal isOpen={this.props.showModal} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Nombre {this.props.title}</ModalHeader>
        <ModalBody>
          Ingrese el nombre de Cotizador para <b>{this.props.entity.name}</b>.
          <br />
           <Input
                id="newNameCoti"
                name="newNameCoti"
                onChange={this.changeValue}                
                placeholder="nombre cotizador"
                type="text"
                 ></Input>
        </ModalBody>
        <ModalFooter>
            <Button id="boton_submit" name="boton_submit" color="primary" onClick={this.submitValue}>Agregar</Button>
            <Button id="boton_quit" name="boton_quit" color="secondary" onClick={this.props.toggle}>Cerrar</Button>
        </ModalFooter>
    </Modal>;


  }
}