import React from 'react';
import IncentiveList from '../../../components/lists/IncentiveList';
import IncentiveRequestList from '../../../components/lists/IncentiveRequestList';

class Index extends React.Component {
    render() {
        return (
            <div className="container content content-incentives">
                <div className="row flex">
                    <div className="col-sm-12">
                        <div className="row">
                            <IncentiveList/>
                            <IncentiveRequestList/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
