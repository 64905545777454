import React from 'react';
import {CustomPaging, FilteringState, IntegratedFiltering, PagingState} from '@devexpress/dx-react-grid';
import {Grid, PagingPanel, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import {Link} from 'react-router-dom';
import {IconContext} from 'react-icons';
import {FaEdit, FaCheckSquare} from 'react-icons/fa';
import EditCotiName from '../Tables/Edit/EditCotiName'


export default class IndexTable extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = 
    {
      app:
      {
        name_table:"",
        name_column:""
      },
      view:{
        index_view: 1,
        page:1,
        per_page:25,
        total_page:1,
        rendered:true      
      },     
      columns: this.getColumns (),
      defaultColumnWidths: this.getColumnsWidth(),
      rows: [],
      entity:{},
      modal:{
        show:false
      }
    }

    this.getData();    
    
  }

  componentDidMount()
  {

  }

  
 
  getData ()
  {
    return [];
  }

  /**
   * [OVERRIDE] retorna el nombre de la columna
   * @returns {string}
   */
  getNameColumn()
  {
    return "";
  }

  editCotiName(data)
  {
    this.setState({entity:data})
    this.setState({modal:{show:true}})    
  }

  stopEditCotiName()
  {
    this.setState({modal:{show:false}}) 
  }

  submitChange(id,coti_name)
  {
    console.log(`Ingresó el ID ${id} y el nombre de cotizador ${coti_name}`)
  }

  getColumns ()
  {
    return [
      {
        name: 'actions',
        title: 'Acción',
          getCellValue: row =>
              <IconContext.Provider value={{color: '#E30012'}} >  
               
                  <FaEdit id={row.id} data={row} onClick={(e)=>{e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            this.editCotiName(row);}}/>
               
               {row.coti_code > 0 ? <FaCheckSquare />: null}
              </IconContext.Provider>
      },
      {
          name: 'id',
          title: 'ID'
      },
      {
          name: 'name',
          title: this.getNameColumn()
      },
      {
          name: 'coti_name',
          title: 'nombre cotizador'
      }
    ];
  }

  

  getColumnsWidth()
  {
    return [
      {columnName: 'actions', width: 80},
      {columnName: 'id', width: 240},
      {columnName: 'name', width: 360},
      {columnName: 'coti_name', width: 240}
    ];
  }
   

  render  ()
  { 
   return <React.Fragment>
     { this.state.modal.show && <EditCotiName 
                    showModal={this.state.modal.show}
                    entity={this.state.entity}
                    title={this.state.app.name_table}
                    toggle={()=>this.setState({modal:{show:false}})}
                    submitChange={this.submitChange}
                     /> }
      
      <Grid rows={this.state.rows} columns={this.state.columns}>
          <FilteringState/>
          <PagingState currentPage={this.state.currentPage}
                        onCurrentPageChange={this.onCurrentPageChange}
                        onPageSizeChange={this.onPageSizeChange}
                        pageSize={this.state.pageSize}
          />
          <CustomPaging totalCount={this.state.totalCount}/>
          <IntegratedFiltering/>
          <Table/>
          <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
          <TableHeaderRow/>
          <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
         
      </Grid>
    </React.Fragment>;
  }

  
}