import isNull from 'lodash/isNull';
import Pusher from 'pusher-js';
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Auth from './lib/Auth';
import MainLayout from './layouts/MainLayout'
import AuthenticatedRoute from './lib/AuthenticatedRoute';
import RealTime from './lib/RealTime';
import User from './models/User';
import LoginPage from './views/Login';
import LogoutPage from './views/Logout';

Pusher.logToConsole = true;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pusher: null,
            role: null
        };
    }

    componentDidMount() {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            forceTLS: true
        });
        const newState = {
            pusher: pusher
        };

        // If user is authenticated
        if (Auth.isAuthenticated()) {
            
            // Request for user details, and finally set state
            User.me().then(result => {
                // If status true set pusher channel
                if (result.status) {
                    const userId = result.data.id;
                    newState.role = result.data.role;

                    RealTime.setChannels(pusher, userId);
                }
            }).finally(() => this.setState(newState));

        } else {
            this.setState(newState);
        }   
    }

    updateRole = role => this.setState({role: role});

    render() {
        if (isNull(this.state.pusher)) {
            return null;
        }

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" component={() => <LoginPage pusher={this.state.pusher} updateRole={this.updateRole}/>}/>
                    <Route exact path="/logout" component={LogoutPage}/>
                    <AuthenticatedRoute path="/" component={() => <MainLayout pusher={this.state.pusher} role={this.state.role}/>}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
