import {parallel, series} from 'async';
import {Wave} from 'better-react-spinkit';
import {clone, isArray, isEmpty, isNil} from 'lodash';
import React from 'react';
import Dropdown from 'react-dropdown';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import Brand from '../../models/Brand';
import VehicleModel from '../../models/VehicleModel';

class VehicleModelForm extends React.Component {
    static INDEX_ROUTE = '/vehicle-models';

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            model: {},
            brands: [],
            formValues: {
                name: '',
                exId: '',
                brandId: ''
            },
            originalFormValues: [],
            processing: false
        };
    }

    componentDidMount() {
        let newState = {
            loading: false,
            model: {},
            brands: [],
            formValues: {},
            originalFormValues: {}
        };
        let tasks = {
            brands: callback => {
                Brand.find().then(result => {
                    if (result.status) {
                        newState.brands = result.data;
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            }
        };

        if (this.isEditMode()) {
            tasks.model = callback => {
                const params = `filter[include]=brand`;

                VehicleModel.findById(this.props.id, params).then(result => {
                    if (result.status) {
                        const model = result.data;
                        newState.model = model;
                        newState.formValues = Object.assign(this.state.formValues, {
                            name: model.name,
                            exId: model.exId,
                            brandId: model.brandId
                        });
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            };
        }

        parallel(tasks, (err, result) => {
            if (this.isEditMode()) {
                if (!result.model) {
                    Alert.error('Modelo inválido');
                    this.props.history.push(VehicleModelForm.INDEX_ROUTE);
                }
            }

            newState.originalFormValues = clone(newState.formValues);

            this.setState(newState);
        });
    }

    getBrandOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.brands.forEach(brand => {
            options.push({value: brand.id, label: brand.name});
        });

        return options;
    };

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(VehicleModelForm.INDEX_ROUTE);

    onChangeName = e => this.setState({formValues: Object.assign(this.state.formValues, {name: e.target.value})});

    onChangeExId = e => this.setState({formValues: Object.assign(this.state.formValues, {exId: e.target.value})});

    onChangeBrandId = option => {
        this.setState({formValues: Object.assign(this.state.formValues, {brandId: option.value})});
    };

    onSave = () => {
        const constraints = {
            name: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el nombre'
                }
            },
            exId: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el ID externo'
                }
            },
            brandId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la marca'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                let initialData = {
                    name: '',
                    exId: '',
                    brandId: ''
                };

                if (!this.isEditMode()) {
                    initialData.enabled = true;
                    initialData.deleted = false;
                }

                let data = Object.assign(initialData, clone(this.state.formValues));
                const newState = {
                    processing: false
                };
                const errorMessages = {
                    name: {
                        error: 'Lo sentimos, ocurrió un error validando el nombre',
                        duplicated: 'El nombre ya está en uso, por favor ingrese otro'
                    },
                    exId: {
                        error: 'Lo sentimos, ocurrió un error validando el ID externo',
                        duplicated: 'El ID externo ya está en uso, por favor ingrese otro'
                    }
                };

                let tasks = {
                    name: callback => {
                        const params = `filter[where][name]=${this.state.formValues.name}`;

                        VehicleModel.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.name.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.name.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.name.error);
                            callback(e);
                        });
                    },
                    exId: callback => {
                        const params = `filter[where][exId]=${this.state.formValues.exId}`;

                        VehicleModel.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.exId.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.exId.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.exId.error);
                            callback(e);
                        });
                    }
                };

                if (this.isEditMode()) {
                    if (this.state.formValues.name === this.state.originalFormValues.name) {
                        delete tasks.name;
                    }

                    if (this.state.formValues.exId === this.state.originalFormValues.exId) {
                        delete tasks.exId;
                    }
                }

                if (isEmpty(tasks)) {
                    tasks.dummy = callback => callback(null, true);
                }

                series(tasks, (err, result) => {
                    if (err) {
                        console.log(err);
                        Alert.error('Error');
                    } else {
                        let values = [];

                        if (!isNil(result.name)) {
                            values.push(result.name.isOk);
                        }

                        if (!isNil(result.exId)) {
                            values.push(result.exId.isOk);
                        }

                        const proceed = isEmpty(values) || values.every(x => x === true);

                        if (proceed) {
                            // Process vehicle model
                            if (this.isEditMode()) {
                                VehicleModel.update(this.props.id, data).then(result => {
                                    if (result.status) {
                                        Alert.info('Modelo editado');
                                    } else {
                                        Alert.error('Error. No se pudo editar el modelo')
                                    }
                                }).finally(() => this.setState(newState));
                            } else {
                                VehicleModel.create(data).then(result => {
                                    if (result.status) {
                                        Alert.info('Modelo creado');
                                        this.props.history.push(VehicleModelForm.INDEX_ROUTE);
                                    } else {
                                        Alert.error('Error. No se pudo crear el modelo')
                                    }
                                }).finally(() => this.setState(newState));
                            }
                        } else {
                            this.setState(newState);
                        }
                    }
                });
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="name">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="name"
                                               name="name"
                                               onChange={this.onChangeName}
                                               placeholder="Nombre *"
                                               type="text"
                                               value={this.state.formValues.name}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="exId">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="exId"
                                               name="exId"
                                               onChange={this.onChangeExId}
                                               placeholder="ID externo *"
                                               type="text"
                                               value={this.state.formValues.exId}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="brandId">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Dropdown id="brandId"
                                                  name="brandId"
                                                  className={"form-control form-control-dropdown"}
                                                  onChange={this.onChangeBrandId}
                                                  options={this.getBrandOptions()}
                                                  placeholder="Marca *"
                                                  value={this.state.formValues.brandId}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(VehicleModelForm);
