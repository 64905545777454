import axios from 'axios';
import {isNull, isObject, isString} from 'lodash';
import URI from 'urijs';
import Auth from '../Auth';
import Session from '../Session';

class Api {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static endpoint = '';

    static buildUrl(endpoint, params = null) {
        let url = endpoint;

        if (!isNull(params) && params !== '') {
            if (isObject(params)) {
                let uri = new URI(url);

                uri.setQuery(params);

                url = uri.readable();
            } else if (isString(params)) {
                url += `?${params}`;
            }
        }

        return url;
    }

    static getApiClient() {
        let config = {
            baseURL: this.baseUrl,
            timeout: 10 * 10000,
            headers: {}
        };

        if (Auth.isAuthenticated()) {
            config.headers['X-Access-Token'] = Session.getCookie();
        }

        return axios.create(config);
    }

    static async count(params = null) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const url = this.buildUrl(this.endpoint + '/count', params);
            const response = await this.getApiClient().get(url);

            if (response.status === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return result;
    }

    static async create(data) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const response = await this.getApiClient().post(this.endpoint, data);

            if (response.status === 200 || response.status === 201) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return await result;
    }

    static async delete(id) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const url = this.buildUrl(`${this.endpoint}/${id}`);
            const response = await this.getApiClient().delete(url);

            if (response.status === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return result;
    }

    static async find(params = null) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const url = this.buildUrl(this.endpoint, params);
            const response = await this.getApiClient().get(url);

            if (response.status === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return result;
    }

    static async findById(id, params = null) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const url = this.buildUrl(`${this.endpoint}/${id}`, params);
            const response = await this.getApiClient().get(url);

            if (response.status === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return result;
    }

    static async get(data, to = '') {
        let result = {
            statusCode: null,
            status: false,
            message: 'Error'
        };

        try {
            const response = await this.getApiClient().get(this.endpoint + to, data);
            result.statusCode = response.status;

            if (response.status >= 200 && response.status < 300) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return await result;
    }

    static async post(data, to = '') {
        let result = {
            statusCode: null,
            status: false,
            message: 'Error'
        };

        try {
            const response = await this.getApiClient().post(this.endpoint + to, data);
            result.statusCode = response.status;

            if (response.status >= 200 && response.status < 300) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return await result;
    }

    static async update(id, data) {
        let result = {
            status: false,
            message: 'Error'
        };

        try {
            const response = await this.getApiClient().patch(this.endpoint + `/${id}`, data);

            if (response.status === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            result.message = e.message;
        }

        return result;
    }
}

export default Api;
