import {Wave} from 'better-react-spinkit';
import {in_array as inArray} from 'locutus/php/array';
import React from 'react';
import {withRouter} from 'react-router-dom';
import CallCenterUserForm from '../../../components/forms/users/CallCenterUserForm';
import ManagementUserForm from '../../../components/forms/users/ManagementUserForm';
import SalesUserForm from '../../../components/forms/users/SalesUserForm';
import Alert from '../../../lib/Alert';
import Constants from '../../../lib/Constants';
import User from '../../../models/User';

class Edit extends React.Component {
    static INDEX_ROUTE = '/users';

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            role: ''
        };
    }

    componentDidMount() {
        let newState = {
            loading: false
        };

        User.findById(this.props.match.params.id).then(result => {
            if (result.status && !result.data.deleted) {
                newState.role = result.data.role;
            } else {
                Alert.error('Usuario inválido');
                this.props.history.push(Edit.INDEX_ROUTE);
            }
        }).catch(e => {
            console.log(e);
            Alert.error('Error');
        }).finally(() => this.setState(newState));
    };

    renderForm = () => {
        const managementRoles = [
            Constants.Role.ADMIN,
            Constants.Role.COORDINATOR,
            Constants.Role.MAIN_MANAGER,
            Constants.Role.BUSINESS_MANAGER,
            Constants.Role.MARKETING_MANAGER,
            Constants.Role.BUSINESS_DELEGATE,
            Constants.Role.DEALERSHIP_MANAGER,
            Constants.Role.SALES_MANAGER,
            Constants.Role.CALL_CENTER_SUPERVISOR,
            Constants.Role.BACKOFFICE,
        ];

        if (inArray(this.state.role, managementRoles)) {
            return (
                <ManagementUserForm id={this.props.match.params.id}
                                    mode={Constants.Form.MODE_EDIT}
                                    role={this.state.role}
                />
            );
        } else if (this.state.role === Constants.Role.SALES) {
            return (
                <SalesUserForm id={this.props.match.params.id}
                               mode={Constants.Form.MODE_EDIT}
                               role={this.state.role}
                />
            );
        } else if (this.state.role === Constants.Role.CALL_CENTER) {
            return (
                <CallCenterUserForm id={this.props.match.params.id}
                               mode={Constants.Form.MODE_EDIT}
                               role={this.state.role}
                />
            );
        }

        return null;
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">EDITAR USUARIO</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderForm()}
            </div>
        );
    }
}

export default withRouter(Edit);
