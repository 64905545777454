import React from 'react';
import { Wave } from 'better-react-spinkit';
import 'react-dropdown/style.css';
import { Form, FormGroup, Label, Button, Input} from 'reactstrap';
import Constants from '../../../lib/Constants';
import User from '../../../models/User';
import Lead from '../../../models/Lead';
import { parallel, series } from 'async';
import { clone, isArray, isEmpty } from 'lodash';
import Alert from '../../../lib/Alert';
import validate from 'validate.js';
import Session from '../../../lib/Session';
import Brand from '../../../models/Brand';
import Dealership from '../../../models/Dealership';
import VehicleModel from '../../../models/VehicleModel';
import Store from '../../../models/Store';

class Create extends React.Component {

    static INDEX_ROUTE = '/leads';

    static defaultDealerships = [
        {
            id: '',
            name: 'Concesionario *'
        }
    ];

    static defaultStores = [
        {
            id: '',
            name: 'Tienda *'
        }
    ];

    static defaultBrands = [
        {
            id: '',
            name: 'Marca *'
        }
    ];

    static defaultVehicleModels = [
        {
            id: '',
            name: 'Modelo *'
        }
    ];

    static defaultDocumentTypes = [
        {
            id: '',
            name: 'Tipo de Documento *'
        },
        {
            id: 'DNI',
            name: 'DNI'
        },
        {
            id: 'CE',
            name: 'CE'
        }
    ];

    static defaultOrigins = [
        {
            id: '',
            name: 'Origen *'
        },
        {
            id: 'Redes Sociales',
            name: 'Redes Sociales'
        },
        {
            id: 'Eventos',
            name: 'Eventos'
        },
        {
            id: 'Sesiones en Vivo',
            name: 'Sesiones en Vivo'
        },
        {
            id: 'Whatsapp',
            name: 'Whatsapp'
        },
        {
            id: 'Llamada Inbound',
            name: 'Llamada Inbound'
        },
        {
            id: 'Formulario Web',
            name: 'Formulario Web'
        }
    ];

    static defaultSalesUsers = [
        {
            id: '',
            firstName: 'Vendedor',
            lastName: ''
        }
    ];

    static user = Session.getUser();

    constructor(props) {
        super(props);

        // Init state variable
        this.state = {
            role: '',
            loading: true,
            model: {},
            dealerships: Create.defaultDealerships,
            stores: Create.defaultStores,
            brands: Create.defaultBrands,
            vehicleModels: Create.defaultVehicleModels,
            departments: Create.defaultDepartments,
            documentTypes: Create.defaultDocumentTypes,
            origins: Create.defaultOrigins,
            salesUsers: Create.defaultSalesUsers,
            formValues: {
                userId: null,
                firstLastName: '', // To remove before submit
                secondLastName: '', // To remove before submit
                firstName: '',
                lastName: '',
                email: '',
                documentType: '',
                documentNumber: '',
                phone: '',
                mobilePhone: '',
                departmentId: '',
                departmentName: '',
                dealershipId: '', // To remove before submit
                store: '',
                storeId: '',
                vehicleModelExIds : '',
                vehicleModelId: '',
                brandExId: '',
                brandId: '',
                origin: '',
                comment: null,
                firstUserId: '',
                backOfficeUserId: Create.user.id
            },
            originalFormValues: {},
            processing: false
        };
    }

    componentDidMount() {
        let newState = {
            loading: false
        };

        let tasks = {
            // Get dealerships
            dealerships: callback => {
                Dealership.find().then(result => {
                    if (result.status) {
                        newState.dealerships = Create.defaultDealerships.concat(result.data);
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            },
            // Get brands
            brands: callback => {
                Brand.find().then(result => {
                    if (result.status) {
                        newState.brands = Create.defaultBrands.concat(result.data);
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            }
        };

        if (this.isEditMode()) {
            // tasks.model = callback => {
            //     User.findById(this.props.id).then(result => {
            //         if (result.status) {
            //             const model = result.data;
            //             newState.model = model;
            //             newState.formValues = Object.assign(this.state.formValues, {
            //                 username: model.username,
            //                 email: model.email,
            //                 firstName: model.firstName,
            //                 lastName: model.lastName,
            //                 idDocNumber: model.idDocNumber,
            //                 campaign: model.campaign,
            //                 enabled: model.enabled
            //             });
            //         }

            //         callback(null, result.status);
            //     }).catch(() => callback(null, false));
            // };
        } else {
            newState.formValues = clone(this.state.formValues);
            tasks.dummy = callback => callback(null, true);
        }

        parallel(tasks, (err, result) => {
            if (this.isEditMode()) {
                if (!result.model) {
                    Alert.error('Lead inválido');
                    this.props.history.push(Create.INDEX_ROUTE);
                }
            }

            newState.originalFormValues = clone(newState.formValues);

            this.setState(newState);
        });
    }

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(Create.INDEX_ROUTE);

    // Start - Form on change value
    // ============================================================================
    onChangeFirstName = e => this.setState({
        formValues: Object.assign(this.state.formValues, { firstName: e.target.value })
    });

    onChangeFirstLastName = e => this.setState({
        formValues: Object.assign(this.state.formValues, { firstLastName: e.target.value })
    });

    onChangeSecondLastName = e => this.setState({
        formValues: Object.assign(this.state.formValues, { secondLastName: e.target.value })
    });

    onChangeEmail = e => this.setState({
        formValues: Object.assign(this.state.formValues, { email: e.target.value })
    });

    onChangeDocumentType = e => this.setState({
        formValues: Object.assign(this.state.formValues, { documentType: e.target.value })
    });

    onChangeDocumentNumber = e => this.setState({
        formValues: Object.assign(this.state.formValues, { documentNumber: e.target.value })
    });

    onChangePhone = e => this.setState({
        formValues: Object.assign(this.state.formValues, { phone: e.target.value })
    });

    onChangeMobilePhone = e => this.setState({
        formValues: Object.assign(this.state.formValues, { mobilePhone: e.target.value })
    });

    onChangeBrandId = e => this.setState({
        formValues: Object.assign(this.state.formValues, { brandId: e.target.value })
    });

    onChangeBrandId = e => {

        // Set value to form
        const brandId = e.target.value;
        let newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, { brandId: brandId, vehicleModelId: ''})
        };

        // Search for vehicle models related to selected brand to populate select
        this.setState(newState, () => {

            // Query
            const params = `filter[where][brandId]=${brandId}&filter[where][enabled]=true&filter[where][deleted]=false`;

            newState = {
                processing: false,
                vehicleModels: []
            };

            // Find vehicle models and fill with result data
            VehicleModel.find(params).then(result => {
                if (result.status) {
                    newState.vehicleModels = Create.defaultVehicleModels.concat(result.data);
                }

                this.setState(newState);
            }).catch(() => this.setState(newState));
        });
    };

    onChangeVehicleModelId = e => this.setState({
        formValues: Object.assign(this.state.formValues, { vehicleModelId: e.target.value })
    });

    onChangeDealershipId = e => {

        // Set value to form
        const dealershipId = e.target.value;
        let newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, { dealershipId: dealershipId, storeId: ''})
        };

        // Search for stores related to dealership and populate select
        this.setState(newState, () => {

            // Query
            const params = `filter[where][dealershipId]=${dealershipId}`;

            newState = {
                processing: false,
                stores: []
            };

            // Find stores and fill with result data
            Store.find(params).then(result => {
                if (result.status) {
                    newState.stores = Create.defaultStores.concat(result.data);
                }

                this.setState(newState);
            }).catch(() => this.setState(newState));
        });
    };

    onChangeStoreId = e => {

        // Set value to form
        const storeId = e.target.value;
        let newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, { storeId: storeId, firstUserId: ''})
        };

        // Search for sales users role to populate select
        this.setState(newState, () => {

            // Query
            const params = `filter[where][storeId]=${storeId}&filter[where][role]=sales&filter[where][enabled]=true&filter[where][deleted]=false`;

            newState = {
                processing: false,
                salesUsers: []
            };

            // Find sales users role and fill with result data
            User.find(params).then(result => {
                if (result.status) {
                    newState.salesUsers = Create.defaultSalesUsers.concat(result.data);
                }

                this.setState(newState);
            }).catch(() => this.setState(newState));
        });
    };
    
    onChangeOrigin = e => this.setState({
        formValues: Object.assign(this.state.formValues, { origin: e.target.value })
    });

    onChangeComment = e => this.setState({
        formValues: Object.assign(this.state.formValues, { comment: e.target.value })
    });

    onChangeFirstUserId = e => this.setState({
        formValues: Object.assign(this.state.formValues, { firstUserId: e.target.value })
    });
    // End - Form on change value
    // ============================================================================

    // Start - Submit form
    // ============================================================================
    onSave = () => {

        // Form validations
        const constraints = {
            firstName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los nombres'
                }
            },
            firstLastName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el apellido paterno'
                }
            },
            secondLastName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el apellido materno'
                }
            },
            email: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el correo electrónico'
                },
                email: {
                    message: 'El correo electrónico es inválido'
                }
            },
            documentType: {
                presence: {
                    allowEmpty: false,
                    message: 'Seleccionar el tipo de documento'
                }
            },
            documentNumber: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el número de documento'
                }
            },
            mobilePhone: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el número de DNI'
                }
            },
            brandId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la marca'
                }
            },
            vehicleModelId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el modelo'
                }
            },
            dealershipId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el concesionario'
                }
            },
            storeId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la tienda'
                }
            },
            origin: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el origen'
                }
            }
        };

        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {

            this.setState({ processing: true }, () => {

                // Set initial data like empty
                let initialData = {
                    userId: null,
                    firstName: '',
                    lastName: '',
                    email: '',
                    documentType: '',
                    documentNumber: '',
                    phone: '',
                    mobilePhone: '',
                    departmentId: '',
                    departmentName: '',
                    store: '',
                    storeId: '',
                    vehicleModelExIds : '',
                    vehicleModelIds: '',
                    brandExId: '',
                    brandId: '',
                    origin: '',
                    comment: null,
                    firstUserId: '',
                    backOfficeUserId: ''
                };

                // Get brand from array to fill brandExId
                const selectedBrand = this.state.brands.filter(element => element.id === this.state.formValues.brandId);
                let brandExId = selectedBrand.length > 0 ? selectedBrand[0].exId : '';

                // Get vehicleModel from array to fill vehicleModelExIds
                const selectedVehicleModel = this.state.vehicleModels.filter(e => e.id === this.state.formValues.vehicleModelId);
                let vehicleModelExId = selectedVehicleModel.length > 0 ? selectedVehicleModel[0].exId : '';

                // Get brand from array to fill brandExId
                const selectedStore = this.state.stores.filter(e => e.id === this.state.formValues.storeId);
                let storeName = selectedStore.length > 0 ? selectedStore[0].name : '';

                // Set data to submit later
                let data = Object.assign(initialData, {
                    callCenterIdDocNumber: null,
                    callCenterUserId: null,
                    userId: this.state.formValues.firstUserId,
                    firstName: this.state.formValues.firstName,
                    lastName: this.state.formValues.firstLastName + ' ' + this.state.formValues.secondLastName,
                    email: this.state.formValues.email,
                    documentType: this.state.formValues.documentType,
                    documentNumber: this.state.formValues.documentNumber,
                    birthDate : null,
                    phone: this.state.formValues.phone,
                    mobilePhone: this.state.formValues.mobilePhone,
                    departmentId: '',
                    departmentName: '',
                    // "provinceName" : "LIMA",
                    // "provinceId" : ObjectId("5c37ce93f34fcc14dc845bc3"),
                    // "districtName" : "RIMAC-LIMA-LIMA",
                    // "districtExId" : "",
                    // "districtId" : null,
                    // "address" : "-",
                    store: storeName,
                    storeId: this.state.formValues.storeId,
                    vehicleModelExIds : [
                        vehicleModelExId
                    ],
                    vehicleModelIds: [
                        this.state.formValues.vehicleModelId
                    ],
                    brandExId: brandExId,
                    brandId: this.state.formValues.brandId,
                    comment: null,
                    info : 'SI',
                    origin: this.state.formValues.origin,
                    purchaseType: null,
                    contact: null,
                    firstUserId: this.state.formValues.firstUserId,
                    backOfficeUserId: this.state.formValues.backOfficeUserId,
                    status : 'checked',
                    visit: null,
                    credit: "-",
                    valuation: "No",
                    testDrive: "No",
                    testDriveDate: null,
                    testDriveSchedule: "-",
                    year: "-",
                    valuationBrand: "-",
                    valuationModel: "-",
                    laborSystem: "-",
                    laborStartDate: null,
                    company: "-",
                    ruc: "-",
                    monthlyIncome: "-",
                    wifeDocument: "-",
                    wifeLaborSystem: "-",
                    wifeMonthlyIncome: "-",
                    photo1: "-",
                    photo2: "-",
                    photo3: "-",
                    photo4: "-"
                });
                // console.log('DEBUG: data:', data);

                let newState = {
                    processing: false
                };
               
                // Database validations
                let tasks = {
                };
                // Database validations

                // If database validations are empty
                if (isEmpty(tasks)) {
                    tasks.dummy = callback => callback(null, true);
                }
                
                // Start proccess to send data
                series(tasks, (err, result) => {

                    // If error show alert
                    // else 
                    if (err) {
                        console.log(err);
                        Alert.error('Error');
                    } else {
                        let values = [];

                        // Set proceed if there is not problems with database validations
                        const proceed = isEmpty(values) || values.every(x => x === true);

                        // If all database validations are ok, proceed
                        // else set new state
                        if (proceed) {

                            // Start - Create Lead
                            Lead.create(data).then(result => {
                                if (result.status) {
                                    newState.originalFormValues = clone(this.state.formValues);
                                    Alert.info('Lead creado');
                                    this.props.history.push(Create.INDEX_ROUTE);
                                } else {
                                    Alert.error('Error. No se pudo crear el lead');
                                }
                                this.setState(newState);

                            }).catch(e => {

                                console.log(e);
                                Alert.error('Error');
                                this.setState(newState);

                            });
                            // End - Create Lead

                        } else {
                            this.setState(newState);
                        }
                    }
                });
            });
        }
    };
    // End - Submit form
    // ============================================================================

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CREAR LEAD</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">

                                {/* Start - Left Column */}
                                <div className="col-sm-6">
                                    {/* Names input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstName">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="firstName"
                                                name="firstName"
                                                onChange={this.onChangeFirstName}
                                                placeholder="Nombres *"
                                                type="text"
                                                value={this.state.formValues.firstName}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Names input */}
                                    {/* Second Lastname input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="secondLastName">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="secondLastName"
                                                name="secondLastName"
                                                onChange={this.onChangeSecondLastName}
                                                placeholder="Apellido Materno *"
                                                type="text"
                                                value={this.state.formValues.secondLastName}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Second Lastname input */}
                                    {/* Email input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="email">
                                                <img alt="" src="/img/svg/icon_email.svg" width="20"/>
                                            </Label>
                                            <Input id="email"
                                                name="email"
                                                onChange={this.onChangeEmail}
                                                placeholder="Correo electrónico *"
                                                type="email"
                                                value={this.state.formValues.email}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Email input */}
                                    {/* Phone input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="phone" title="Teléfono fijo">
                                                <img alt="" src="/img/svg/icon_phone.svg" width="20" />
                                            </Label>
                                            <Input id="phone"
                                                name="phone"
                                                onChange={this.onChangePhone}
                                                placeholder={'Teléfono fijo'}
                                                type="text"
                                                value={this.state.formValues.phone}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Phone input */}
                                    {/* Brand select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="brandId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="brandId"
                                                name="brandId"
                                                onChange={this.onChangeBrandId}
                                                type="select"
                                                value={this.state.formValues.brandId}
                                            >
                                                {this.state.brands.map(brand =>
                                                <option key={`brand-${brand.id}`}
                                                        value={brand.id}>{brand.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Brand select */}
                                    {/* Vehicle Model select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="vehicleModelId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="vehicleModelId"
                                                name="vehicleModelId"
                                                onChange={this.onChangeVehicleModelId}
                                                type="select"
                                                value={this.state.formValues.vehicleModelId}
                                            >
                                                {this.state.vehicleModels.map(vehicleModel =>
                                                <option key={`vehicleModel-${vehicleModel.id}`}
                                                        value={vehicleModel.id}>{vehicleModel.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Vehicle Model select */}
                                    {/* Origin select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="origin">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="origin"
                                                name="origin"
                                                onChange={this.onChangeOrigin}
                                                type="select"
                                                value={this.state.formValues.origin}
                                            >
                                                {this.state.origins.map(origin =>
                                                <option key={`origin-${origin.id}`}
                                                        value={origin.id}>{origin.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Origin select */}
                                </div>
                                {/* End - Left Column */}
                                {/* Start - Right Column */}
                                <div className="col-sm-6">
                                    {/* First Lastname input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstLastName">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="firstLastName"
                                                name="firstLastName"
                                                onChange={this.onChangeFirstLastName}
                                                placeholder="Apellido Paterno *"
                                                type="text"
                                                value={this.state.formValues.firstLastName}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* First Lastname input */}
                                    {/* Document Type select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="documentType">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="documentType"
                                                name="documentType"
                                                onChange={this.onChangeDocumentType}
                                                type="select"
                                                value={this.state.formValues.documentType}
                                            >
                                                {this.state.documentTypes.map(documentType =>
                                                <option key={`documentType-${documentType.id}`}
                                                        value={documentType.id}>{documentType.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Document Type select */}
                                    {/* Document Number input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="documentNumber">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="documentNumber"
                                                name="documentNumber"
                                                onChange={this.onChangeDocumentNumber}
                                                placeholder="Número de Documento *"
                                                type="text"
                                                value={this.state.formValues.documentNumber}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Document Number input */}
                                    {/* Mobile Phone input */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="phone" title="Teléfono fijo">
                                                <img alt="" src="/img/svg/icon_phone.svg" width="20" />
                                            </Label>
                                            <Input id="mobilePhone"
                                                name="mobilePhone"
                                                onChange={this.onChangeMobilePhone}
                                                placeholder="Teléfono móvil *"
                                                type="text"
                                                value={this.state.formValues.mobilePhone}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/* Mobile Phone input */}
                                    {/* Dealership select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="dealershipId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="dealershipId"
                                                name="dealershipId"
                                                onChange={this.onChangeDealershipId}
                                                type="select"
                                                value={this.state.formValues.dealershipId}
                                            >
                                                {this.state.dealerships.map(dealership =>
                                                <option key={`dealership-${dealership.id}`}
                                                        value={dealership.id}>{dealership.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Dealership select */}
                                    {/* Store select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="storeId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="storeId"
                                                name="storeId"
                                                onChange={this.onChangeStoreId}
                                                type="select"
                                                value={this.state.formValues.storeId}
                                            >
                                                {this.state.stores.map(store =>
                                                <option key={`store-${store.id}`}
                                                        value={store.id}>{store.name}
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Store select */}
                                    {/* Sales User select */}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstUserId">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                            </Label>
                                            <Input id="firstUserId"
                                                name="firstUserId"
                                                onChange={this.onChangeFirstUserId}
                                                type="select"
                                                value={this.state.formValues.firstUserId}
                                            >
                                                {this.state.salesUsers.map(saleUser =>
                                                <option key={`saleUser-${saleUser.id}`}
                                                        value={saleUser.id}>{saleUser.firstName} {saleUser.lastName} 
                                                </option>
                                                )}
                                            </Input>
                                        </div>
                                    </FormGroup>
                                    {/* Sales User select */}
                                </div>
                                {/* End - Right Column */}
                            </div>
                        </div>
                        <div className="col-sm-10 col-2 pad0">
                            <div className="flex flex-btns">
                                <div>
                                    {this.renderSubmitButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Create;
