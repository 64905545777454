import {Wave} from 'better-react-spinkit';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {FormGroup, Input, Label} from 'reactstrap';
import Alert from '../../../lib/Alert';
import Notification from '../../../models/Notification';
import User from '../../../models/User';

class ResendButton extends React.Component {
    onClick = e => {
        e.preventDefault();

        if (this.props.notificationId) {
            confirmAlert({
                title: 'Reenviar notificación',
                message: '¿Estás segur@?',
                buttons: [
                    {
                        label: 'Sí',
                        onClick: () => this.resend(this.props.notificationId)
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        }
    };

    resend = notificationId => {
        const to = '/resends';
        const data = {
            id: notificationId
        };

        Notification.post(data, to).then(result => {
            if (result.status) {
                Alert.info(result.data.message);
            } else {
                Alert.error('Error. No se pudo reenviar la notificación');
            }
        });
    };

    render() {
        return (
            <a href="/" onClick={this.onClick}>
                <img alt="" className="icon_actualizar" src="/img/svg/icon_actualizar.svg" width="30"/>
            </a>
        );
    }
}

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            notifications: [],
            message: '',
            processing: false
        };
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        let newState = {
            loading: false
        };
        const params = `filter[order]=message ASC`;

        Notification.find(params).then(result => {
            if (result.status) {
                newState.notifications = result.data;
            }
        }).finally(() => this.setState(newState));
    };

    onChangeMessage = e => {this.setState({message: e.target.value})};

    onKeyPress = e => {
        if (e.key === 'Enter') {
            const message = this.state.message.trim();

            if (message !== '') {
                this.setState({processing: true}, () => {
                    User.me().then(userResult => {
                        const data = {
                            userId: userResult.data.id,
                            message: this.state.message
                        };

                        Notification.create(data).then(result => {
                            if (result.status) {
                                Alert.info('Notificación enviada');
                                this.setState({message: '', processing: false}, () => this.load());
                            } else {
                                Alert.error('Error. No se pudo enviar la notificación');
                                this.setState({ processing: false});
                            }
                        });
                    });
                });
            }
        }
    };

    renderSearchInput = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626"/>
            );
        }

        return (
            <Input id="message"
                   name="message"
                   onChange={this.onChangeMessage}
                   onKeyPress={this.onKeyPress}
                   type="text"
            />
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">NOTIFICACIONES</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="InputBG">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="message">
                                                <img alt="" src="/img/svg/icon_lapizb.svg" width="20"/>
                                            </Label>
                                            {this.renderSearchInput()}
                                        </div>
                                        <img alt="" className="icon2" src="/img/svg/icon1.svg" style={{backgroundColor: '#CFCFCF'}} width="30"/>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.notifications.map(notification =>
                                <div key={`notification-${notification.id}`} className="col-sm-6 mrgBtm20">
                                    <div className="BoxNotifications">
                                        <div className="borderRight"/>
                                        <div>
                                            {notification.message}
                                        </div>
                                        <div>
                                            <ResendButton notificationId={notification.id}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
