import {isArray, isEmpty, isNil, isObject, isString, isUndefined} from 'lodash';
import moment from 'moment';
import Constants from './Constants';

class Utils {
  static formatDate = (value, dateFormat = 'YYYY-MM-DD', timeFormat = 'T00:00:00.000Z') => {
    return moment(value).format(dateFormat) + timeFormat;
  };

  static getReportParams = filter => {
    let params = [];

    if (Utils.isUsable(filter)) {
      for (let field in filter) {
        if (filter.hasOwnProperty(field) && Utils.isUsable(filter[field])) {
          params.push(`${field}=${filter[field]}`);
        }
      }
    }

    return params;
  };

  static isUsable = value => {
    if (isString(value)) {
      return !isNil(value) && value !== '';
    } else if (isArray(value) || isObject(value)) {
      return !isNil(value) && !isEmpty(value);
    }

    return !isNil(value);
  };

  static getLeadStatusName = status => {
    let statusNames = {};
    statusNames[Constants.Lead.STATUS_CREATED] = 'Creado';
    statusNames[Constants.Lead.STATUS_FAILED] = 'Fallido';
    statusNames[Constants.Lead.STATUS_DISCARDED] = 'Descartado';
    statusNames[Constants.Lead.STATUS_CHECKED] = 'Call center';
    statusNames[Constants.Lead.STATUS_UNATTENDED] = 'Derivado';
    statusNames[Constants.Lead.STATUS_ATTENDED] = 'Atendido';

    return !isNil(statusNames[status]) ? statusNames[status] : '';
  };

  static getSystemIssueStatusName = status => {
    let statusNames = {};
    statusNames[Constants.SystemIssue.STATUS_UNSOLVED] = 'Pendiente';
    statusNames[Constants.SystemIssue.STATUS_SOLVING] = 'Resolviendo';
    statusNames[Constants.SystemIssue.STATUS_SOLVED] = 'Resuelta';
    statusNames[Constants.SystemIssue.STATUS_DISCARDED] = 'Descartada';

    return !isNil(statusNames[status]) ? statusNames[status] : '';
  };

  static getSystemIssueTypeName = status => {
    let typeNames = {};
    typeNames[Constants.SystemIssue.TYPE_FAILED_LEAD] = 'Lead fallido';

    return !isNil(typeNames[status]) ? typeNames[status] : '';
  };

  static getUserRoleName = role => {
    const roleNames = {};
    roleNames[Constants.Role.ROOT] = 'Root';
    roleNames[Constants.Role.WEB_SERVICE] = 'Servicios web';
    roleNames[Constants.Role.ADMIN] = 'Administrador';
    roleNames[Constants.Role.COORDINATOR] = 'Coordinador';
    roleNames[Constants.Role.MAIN_MANAGER] = 'Gerente comercial y de márketing';
    roleNames[Constants.Role.BUSINESS_MANAGER] = 'Gerente comercial';
    roleNames[Constants.Role.MARKETING_MANAGER] = 'Gerente de márketing';
    roleNames[Constants.Role.BUSINESS_DELEGATE] = 'Delegado comercial';
    roleNames[Constants.Role.DEALERSHIP_MANAGER] = 'Gerente de concesionario';
    roleNames[Constants.Role.SALES_MANAGER] = 'Jefe de tienda';
    roleNames[Constants.Role.SALES] = 'Asesor de ventas';
    roleNames[Constants.Role.CALL_CENTER_SUPERVISOR] = 'Supervisor de call center';
    roleNames[Constants.Role.CALL_CENTER] = 'Asesor de call center';
    roleNames[Constants.Role.BACKOFFICE] = 'Backoffice';
    const roleName = roleNames[role];

    return !isUndefined(roleName) ? roleName : '';
  };
}

export default Utils;
