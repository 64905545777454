import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import Session from './Session';

class Auth {
    static isAuthenticated = () => {
        const cookie = Session.getCookie();

        return !isNull(cookie) && !isUndefined(cookie);
    };

    static logout = () => {
        Session.removeUser();
        Session.removeCookie();
    }
}

export default Auth;
