import jsCookie from 'js-cookie';
import isUndefined from 'lodash/isUndefined';
import store from 'store';

class Session {
    static keys = {
        COOKIE: process.env.REACT_APP_COOKIE_NAME,
        USER: 'user'
    };

    static getCookie = () => jsCookie.get(Session.keys.COOKIE);

    static getUser = () => {
        let user = {};
        const sessionUser = store.get(Session.keys.USER);

        if (!isUndefined(sessionUser)) {
            user = sessionUser;
        }

        return user;
    };

    static removeCookie = () => jsCookie.remove(Session.keys.COOKIE);

    static removeUser = () => store.remove(Session.keys.USER);

    static setCookie = value => jsCookie.set(Session.keys.COOKIE, value);

    static setUser = user => store.set(Session.keys.USER, user);
}

export default Session;
