import {FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';
import {Grid, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {Wave} from 'better-react-spinkit';
import {isEmpty, isNil} from 'lodash';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {IconContext} from 'react-icons';
import {FaEdit, FaTrash, FaUserAlt} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';
import Alert from '../../../lib/Alert';
import Store from '../../../models/Store';

class DeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        };
    }

    delete = () => {
        this.setState({processing: true}, () => {
            const data = {
                enabled: false,
                deleted: true
            };
            const newState = {
                processing: false
            };
            const errorMessage = 'Error. No se pudo borrar la tienda';

            Store.update(this.props.row.id, data).then(result => {
                if (result.status) {
                    this.props.onDelete(this.props.row);
                    Alert.info('Tienda borrada');
                } else {
                    Alert.error(errorMessage);
                }
            }).catch(e => {
                console.log(e);
                Alert.error(errorMessage);
            }).finally(() => this.setState(newState));
        });
    };

    onClick = e => {
        e.preventDefault();
        confirmAlert({
            title: `Borrar tienda ${this.props.row.name}`,
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.delete()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    render() {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <span>
                <a id={`deleteLink-${this.props.row.id}`} className="pl-1" href="/" onClick={this.onClick}>
                    <FaTrash/>
                </a>
                <UncontrolledTooltip placement="top" target={`deleteLink-${this.props.row.id}`}>
                    Borrar {this.props.row.name}
                </UncontrolledTooltip>
            </span>
        );
    }
}

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            columns: [
                {
                    name: 'actions',
                    title: 'Acción',
                    getCellValue: row =>
                        <IconContext.Provider value={{color: '#E30012'}}>
                            <Link id={`editLink-${row.id}`} to={`/stores/${row.id}/edit`}>
                                <FaEdit/>
                            </Link>
                            <UncontrolledTooltip placement="top" target={`editLink-${row.id}`}>
                                Editar {row.name}
                            </UncontrolledTooltip>
                            <Link id={`salesManagersLink-${row.id}`} to={`/stores/${row.id}/sales-managers`}>
                                <FaUserAlt/>
                            </Link>
                            <UncontrolledTooltip placement="top" target={`salesManagersLink-${row.id}`}>
                                Jefes de tienda
                            </UncontrolledTooltip>
                            <DeleteButton row={row} onDelete={this.onDelete}/>
                        </IconContext.Provider>
                },
                {
                    name: 'name',
                    title: 'Nombre'
                },
                {
                    name: 'dealershipName',
                    title: 'Concesionario',
                    getCellValue: row => !isNil(row.dealership) ? <Link to={`/dealerships/${row.dealership.id}/edit`}>{row.dealership.name}</Link> : ''
                },
                {
                    name: 'brandNames',
                    title: 'Marcas',
                    getCellValue: row => !isNil(row.brands) && !isEmpty(row.brands) ? row.brands.map(brand => brand.name).join(', ') : ''
                },
                {
                    name: 'usersFullnames',
                    title: 'Asesores de ventas',
                    getCellValue: row => !isNil(row.users) && !isEmpty(row.users) ? row.users.map(user => <p key={`user-${user.id}`}><Link to={`/users/${user.id}/edit`}>{[user.firstName, user.lastName].join(' ')}</Link></p>) : ''
                },
                {
                    name: 'salesManagerNames',
                    title: 'Jefes de tienda',
                    getCellValue: row => {
                        if (!isNil(row.salesManagers) && !isEmpty(row.salesManagers)) {
                            return row.salesManagers.map(salesManager => salesManager.user ? <p key={`salesManager-${salesManager.id}`}><Link to={`/users/${salesManager.user.id}/edit`}>{[salesManager.user.firstName, salesManager.user.lastName].join(' ')}</Link></p> : null)
                        }

                        return '';
                    }
                },
                {
                    name: 'departmentName',
                    title: 'Departamento',
                    getCellValue: row => !isNil(row.district) && !isNil(row.district.province) && !isNil(row.district.province.department) ? row.district.province.department.name : ''
                },
                {
                    name: 'provinceName',
                    title: 'Provincia',
                    getCellValue: row => !isNil(row.district) && !isNil(row.district.province) ? row.district.province.name : ''
                },
                {
                    name: 'districtName',
                    title: 'Distrito',
                    getCellValue: row => !isNil(row.district) ? row.district.name : ''
                },
                {
                    name: 'address',
                    title: 'Dirección'
                },
                {
                    name: 'statusName',
                    title: 'Estado',
                    getCellValue: row => !isNil(row.enabled) ? (row.enabled ? 'Habilitado' : 'Deshabilitado') : ''
                }
            ],
            columnExtensions: [
                {columnName: 'actions', filteringEnabled: false}
            ],
            defaultColumnWidths: [
                {columnName: 'actions', width: 180},
                {columnName: 'name', width: 360},
                {columnName: 'dealershipName', width: 240},
                {columnName: 'brandNames', width: 180},
                {columnName: 'usersFullnames', width: 240},
                {columnName: 'salesManagerNames', width: 240},
                {columnName: 'departmentName', width: 180},
                {columnName: 'provinceName', width: 180},
                {columnName: 'districtName', width: 180},
                {columnName: 'address', width: 240},
                {columnName: 'statusName', width: 180}
            ],
            rows: []
        };
    }

    componentDidMount() {
        const params = `filter={"order":"name ASC","where":{"deleted":false},"include":[{"relation":"dealership","where":{"deleted":false}},{"relation":"brands"},{"relation":"district"},{"relation":"users","scope":{"where":{"deleted":false}}},{"relation":"salesManagers","scope":{"include":{"relation":"user","scope":{"where":{"deleted":false}}}}}]}`;

        Store.find(params).then(result => {
            if (result.status) {
                this.setState({loading: false, rows: result.data});
            }
        });
    }

    onDelete = row => this.setState({rows: this.state.rows.filter(x => x.id !== row.id)});

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">TIENDAS</h2>
                                <div className="subline"/>
                            </div>
                            <div>
                                <Link to="/stores/create">
                                    <img alt="" src="/img/svg/icon_mas_rojo.svg" width="25"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid rows={this.state.rows} columns={this.state.columns}>
                                    <FilteringState columnExtensions={this.state.columnExtensions}/>
                                    <IntegratedFiltering/>
                                    <Table/>
                                    <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                                    <TableHeaderRow/>
                                    <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
