import {Wave} from 'better-react-spinkit';
import {isArray} from 'lodash';
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../../lib/Alert';
import Setting from '../../../models/Setting';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            processing: false,
            model: {},
            formValues: {
                reassignmentDelay: ''
            }
        };
    }

    componentDidMount() {
        const newState = {
            loading: false
        };
        const errorMessage = 'Error. No se pudo cargar la configuración';
        const params = `filter[where][key]=reassignmentDelay`;

        Setting.find(params).then(result => {
            if (result.status) {
                newState.model = result.data[0];
                newState.formValues = Object.assign(this.state.formValues, {
                    reassignmentDelay: (((newState.model.value) / 1000) / 60)
                });

                this.setState(newState);
            } else {
                Alert.error(errorMessage);
            }
        }).catch(e => {
            console.log(e);
            Alert.error('Error');
        })
    }

    onCancel = () => this.props.history.goBack();

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>GUARDAR</Button>
            </div>
        );
    };

    onChangeReassignmentDelay = e => this.setState({
        formValues: Object.assign(this.state.formValues, {reassignmentDelay: e.target.value})
    });

    onSave = () => {
        const constraints = {
            reassignmentDelay: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el nombre'
                },
                numericality: {
                    onlyInteger: true,
                    strict: true,
                    greaterThan: 0,
                    message: 'Ingrese una cantidad correcta'
                }
            }
        };

        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                const newState = {
                    processing: false
                };
                const data = {
                    value: this.state.formValues.reassignmentDelay * 60 * 1000
                };

                Setting.update(this.state.model.id, data).then(result => {
                    if (result.status) {
                        Alert.info('Configuración editada');
                    } else {
                        Alert.error('Error. No se pudo editar la configuración');
                    }
                }).finally(() => this.setState(newState));
            });
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CONFIGURACIÓN</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">
                                <div className="col-sm-12 form-group">
                                    <Label for="reassignmentDelay">
                                        <strong>{this.state.model.name}</strong>
                                    </Label>
                                    <FormGroup>
                                        <Input id="reassignmentDelay"
                                               name="reassignmentDelay"
                                               onChange={this.onChangeReassignmentDelay}
                                               placeholder="Ingrese una cantidad de minutos *"
                                               type="text"
                                               value={this.state.formValues.reassignmentDelay}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-2 pad0">
                            <div className="flex flex-btns">
                                {this.renderSubmitButton()}
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withRouter(Index);
