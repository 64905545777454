import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from './Auth';

const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        if (Auth.isAuthenticated()) {
            return <Component {...props}/>;
        }

        return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
    }}/>
);

export default AuthenticatedRoute;
