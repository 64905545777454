import _IndexTable  from './_IndexTable';
import VehicleModel from '../../../../models/VehicleModel'

export default class ModelTable extends _IndexTable {
  
  constructor(props)
  {
    super(props);
    this.config = this.config.bind(this);
    this.submitChange = this.submitChange.bind(this);
    
  }

  config()
  {
     this.setState({app:{
       name_table:"Modelos",
       name_column:"Nombre modelos"
     }})
  }
  
  /**
   * [OVERRIDE] retorna el nombre de la columna
   * @returns {string}
   */
   getNameColumn()
   {
     return "Nombre modelos";
   }
 
  getData ()
  {
    const params = 'filter[where][enabled]=true&filter[where][deleted]=false'
    VehicleModel.find(params).then(x=> {this.setState({rows:x.data})})
  }

  async submitChange(id,coti_name)
  {
    var temp = await  VehicleModel.changeCotiName(id,coti_name);
    var data = await VehicleModel.find();
    this.setState({rows: data.data})
  }
}