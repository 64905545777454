import isEmpty from 'lodash/isEmpty';
import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import Session from '../lib/Session';
import Utils from '../lib/Utils';
/* Views */
import CallCenterDetailView from '../views/call-center/Detail';
import CallCenterIndexView from '../views/call-center/Index';
import CreateDealershipView from '../views/administrator/dealerships/Create';
import EditDealershipView from '../views/administrator/dealerships/Edit';
import DealershipsIndexView from '../views/administrator/dealerships/Index';
import FailedLeadsIndexView from '../views/administrator/failed-leads/Index';
import CreateIncentiveView from '../views/administrator/incentives/Create';
import EditIncentiveView from '../views/administrator/incentives/Edit';
import IncentivesIndexView from '../views/administrator/incentives/Index';
import LeadsIndexView from '../views/administrator/leads/Index';
import CreateLeadView from '../views/administrator/leads/Create';
import NotificationsIndexView from '../views/administrator/notifications/Index';
import SettingsIndexView from '../views/administrator/settings/Index';
import CreateStoreView from '../views/administrator/stores/Create';
import EditStoreView from '../views/administrator/stores/Edit';
import StoresIndexView from '../views/administrator/stores/Index';
import SalesManagersView from '../views/administrator/stores/SalesManagers';
import SystemIssuesIndexView from '../views/administrator/system-issues/Index';
import CreateUserView from '../views/administrator/users/Create';
import EditUserView from '../views/administrator/users/Edit';
import UsersIndexView from '../views/administrator/users/Index';
import CreateVehicleModelView from '../views/administrator/vehicle-models/Create';
import EditVehicleModelView from '../views/administrator/vehicle-models/Edit';
import VehicleModelsIndexView from '../views/administrator/vehicle-models/Index';
import ReportsIndexView from '../views/reports/Index';
import CotizadorIndexView from '../views/administrator/cotizador/Index'
import IndexView from '../views/Index';
import NotFoundView from '../views/NotFound';
import Constants from '../lib/Constants';
import Password from '../views/Password';

class MainLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        };
    }

    /**
     * Function that set and return second level menu
     * according to user role
     * @returns array
     */
    getSubMenuItems = () => {
        const userRole = this.props.role || '';
        //console.log('DEBUG: getSubMenuItems(): --> userRole', userRole);
        const subItems = [
            {
                component:
                    <div key="subMenuItem-users">
                        <Link to="/users">
                            Usuarios
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.SALES_MANAGER,
                ]
            },
            {
                component:
                    <div key="subMenuItem-dealerships">
                        <Link to="/dealerships">
                            Concesionarios
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                component:
                    <div key="subMenuItem-stores">
                        <Link to="/stores">
                            Tiendas
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                component:
                    <div key="subMenuItem-notifications">
                        <Link to="/notifications">
                            Notificaciones
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                component:
                    <div key="subMenuItem-leads">
                        <Link to="/leads">
                            Leads
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER,
                    Constants.Role.CALL_CENTER_SUPERVISOR,
                    Constants.Role.BACKOFFICE,
                ]
            },
            {
                component:
                    <div key="subMenuItem-failedLeads">
                        <Link to="/failed-leads">
                            Leads fallidos
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            },
            {
                component:
                    <div key="subMenuItem-incentives">
                        <Link to="/incentives">
                            Incentivos
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                component:
                    <div key="subMenuItem-vehicleModels">
                        <Link to="/vehicle-models">
                            Modelos
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                component:
                    <div key="subMenuItem-systemIssues">
                        <Link to="/system-issues">
                            Incidencias
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            },
            {
                component: 
                    <div key="CotizadorIndexView">
                        <Link to="/cotizador">
                            Cotizador
                        </Link>
                    </div>
                ,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    
                ]
            }
        ];

        return subItems.filter(subItem => !isEmpty(subItem.roles.filter(role => role === userRole)));
    };

    /**
     * Function that set and return first level menu
     * according to every user role
     * 
     * @returns array
     */
    getMenuItems = () => {
        const userRole = this.props.role || '';
        const subMenuItems = this.getSubMenuItems();
        const items = [
            {
                component: (
                    <div key="menuItem-callCenter" className="ItemMenu">
                        <Link to="/call-center">
                            <img alt="" src="/img/svg/icon_callcenter.svg" width="22"/>
                            Call Center
                        </Link>
                    </div>
                ),
                roles: [
                    Constants.Role.CALL_CENTER
                ]
            },
            {
                component: (
                    <div key="menuItem-administrator" className="ItemMenu">
                        <a href="/" className="m-submenu">
                            <img alt="" src="/img/svg/icon_administrador.svg" width="16"/> Administrador
                        </a>
                        <div className="submenu_item">
                            {subMenuItems.map(subMenuItem => subMenuItem.component)}
                        </div>
                    </div>
                ),
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER,
                    Constants.Role.CALL_CENTER_SUPERVISOR,
                    Constants.Role.BACKOFFICE
                ]
            },
            {
                component: (
                    <div key="menuItem-reports" className="ItemMenu">
                        <Link to="/reports">
                            <img alt="" src="/img/svg/icon_indicadores.svg" width="22"/> Indicadores
                        </Link>
                    </div>
                ),
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.BUSINESS_DELEGATE,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER
                ]
            },
            {
                component: (
                    <div key="menuItem-settings" className="ItemMenu">
                        <Link to="/settings">
                            <img alt="" src="/img/svg/configuracion.svg" width="22"/> Configuración
                        </Link>
                    </div>
                ),
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            }
        ];

        return items.filter(item => !isEmpty(item.roles.filter(role => role === userRole)));
    };

    getRoutes = () => {
        const userRole = this.props.role || '';
        const routes = [
            {
                path: '/call-center/:id',
                component: CallCenterDetailView,
                roles: [
                    Constants.Role.CALL_CENTER
                ]
            },
            {
                path: '/call-center',
                component: () => <CallCenterIndexView pusher={this.props.pusher}/>,
                roles: [
                    Constants.Role.CALL_CENTER
                ]
            },
            {
                path: '/users',
                component: UsersIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.SALES_MANAGER
                ]
            },
            {
                path: '/users/create',
                component: CreateUserView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/users/:id/edit',
                component: EditUserView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/dealerships',
                component: DealershipsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/dealerships/create',
                component: CreateDealershipView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/dealerships/:id/edit',
                component: EditDealershipView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/stores',
                component: StoresIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/stores/create',
                component: CreateStoreView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/stores/:id/edit',
                component: EditStoreView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/stores/:id/sales-managers',
                component: SalesManagersView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/notifications',
                component: NotificationsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/leads',
                component: LeadsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER,
                    Constants.Role.CALL_CENTER_SUPERVISOR,
                    Constants.Role.BACKOFFICE
                ]
            },
            {
                path: '/leads/create',
                component: CreateLeadView,
                roles: [
                    Constants.Role.BACKOFFICE
                ]
            },
            {
                path: '/failed-leads',
                component: FailedLeadsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            },
            {
                path: '/system-issues',
                component: SystemIssuesIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            },
            {
                path: '/incentives/create',
                component: CreateIncentiveView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/incentives/edit/:id',
                component: EditIncentiveView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/incentives',
                component: IncentivesIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/vehicle-models',
                component: VehicleModelsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/vehicle-models/create',
                component: CreateVehicleModelView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/vehicle-models/:id/edit',
                component: EditVehicleModelView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN
                ]
            },
            {
                path: '/reports',
                component: ReportsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.BUSINESS_DELEGATE,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER
                ]
            },
            {
                path: '/settings',
                component: SettingsIndexView,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR
                ]
            },
            {
                path: '/password',
                component: Password,
                roles: [
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                    Constants.Role.COORDINATOR,
                    Constants.Role.MAIN_MANAGER,
                    Constants.Role.BUSINESS_MANAGER,
                    Constants.Role.MARKETING_MANAGER,
                    Constants.Role.BUSINESS_DELEGATE,
                    Constants.Role.DEALERSHIP_MANAGER,
                    Constants.Role.SALES_MANAGER,
                    Constants.Role.CALL_CENTER_SUPERVISOR,
                    Constants.Role.CALL_CENTER,
                    Constants.Role.BACKOFFICE
                ]
            },
            {
                path:'/cotizador',
                component: CotizadorIndexView,
                roles:[
                    Constants.Role.ROOT,
                    Constants.Role.ADMIN,
                   
                ]
            }
        ];

        return routes.filter(route => !isEmpty(route.roles.filter(role => role === userRole)));
    };

    toggle = () => this.setState({dropdownOpen: !this.state.dropdownOpen});

    render() {
        const user = Session.getUser();
        const fullname = [user.firstName, user.lastName].join(' ');
        const menuItems = this.getMenuItems();
        const routes = this.getRoutes();

        return (
            <div className="globalContent">
                <div className="menuLeft">
                    <div>
                        <Link to="/">
                            <img alt="" src="/img/svg/logo_white.svg" className="logo_white" width="220"/>
                        </Link>
                    </div>
                    {menuItems.map(menuItem => menuItem.component)}/>)}
                </div>
                <div>
                    <ButtonDropdown className="dropdown_user" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle>
                            {fullname}
                            <img alt="" className="img-fluid" src="/img/user.png" width="60"/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="">{Utils.getUserRoleName(user.role)}</DropdownItem>
                            <DropdownItem href="/password">Cambiar contraseña</DropdownItem>
                            <DropdownItem href="/logout">Cerrar sesión</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
                <Switch>
                    {routes.map((route, i) => <Route key={`route-${i}`}
                                                     exact
                                                     path={route.path}
                                                     component={route.component}
                                              />
                    )}
                    <Route exact path="/" component={IndexView}/>
                    <Route component={NotFoundView}/>
                </Switch>
                <Alert stack={{limit: 4}}/>
            </div>
        );
    }
}

export default MainLayout;
