import {Wave} from 'better-react-spinkit';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {isEmpty} from 'lodash';
import React from 'react';
import Constants from '../../lib/Constants';
import Utils from '../../lib/Utils';
import Report from '../../models/Report';

class LeadsPerMonth extends React.Component
{
    
    constructor(props)
    {
        super(props);
        this.state = {
            loading:true,
            data:[],
            options:{}
        }
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: true}, () => this.load());
    }

    componentDidMount() {
        this.load();
    }





    load = () => {
        
        if (this.props.filter.date_per_month)
        {
            var sep = this.props.filter.date_per_month.split('-');
            if (sep[0] < 2000) 
            {
                this.setState({loading: false, data: []});
                return;
            }

        }
        
        let to = `/${Constants.Report.LEADS_PER_MONTH}`;

        const params = Utils.getReportParams(this.props.filter);

        if (!isEmpty(params)) {
            to += '?' + params.join('&');
        }
        
        
        Report.get({}, to).then(result => {
            
            var temp = this.datetoarray(result.data);
            
            
            
            const options = {
                chart: {
                    backgroundColor: '#CED2d5',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.4)',
                        offsetX: 1,
                        offsetY: 1,
                        opacity: '0.3',
                        width: 5
                    },
                    width: 760,
                    height: 350,
                    animation: false,
                    type: 'line'
                  },
                  title: {
                    text: '',
                    y: 30
                },
                  xAxis: {
                    categories: temp.days
                  },
                  yAxis: {
                    title: {
                      text: 'Derivados'
                    }
                  },
                  plotOptions: {
                    line: {
                      dataLabels: {
                        enabled: true
                      },
                      enableMouseTracking: false
                    },
                    color:['#4886A','#786A'],
                    backgroundColor:['#4886A','#786A'],
                  },
                  series: [{
                    name: temp.last.name,
                    data: temp.last.data,
                    //colorByPoint:Array(12).fill('#E40012'),
                    
                  }, {
                    name: temp.current.name,
                    data: temp.current.data
                    // color:Array(12).fill('#4786A'),
                    // backgroundColor:Array(12).fill('#4886A'),
                    
                  }]
                
            };
    
            this.setState({loading: false, data: temp, options: options});
        });

        

    }
    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={40}/>
            );
        }

        return (
            <div className={"boxGraphics"}>
                <div>
                    <h4>Ratio de atención</h4>
                    <HighchartsReact highcharts={Highcharts} options={this.state.options}/>
                </div>
            </div>
        );
    }
    datetoarray(data)
    {
        const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "junio",
        "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"
        ]; 

        var date ;
        if (this.props.filter.date_per_month)
        {
            var sep = this.props.filter.date_per_month.split('-');
            date = new Date(sep[0],sep[1]-1,1);  

        }
        else
        {
            date = new Date();
        }
        
        var first_day = 1;
        var last_day_last_month = new Date(date.getFullYear(), date.getMonth(), 0);
        //console.log(last_day_last_month);
        var last_day_current_month = new Date(date.getFullYear(), date.getMonth()+1, 0);
        //console.log(last_day_current_month);
        

        
        // if (data.length==0)
        // {
            
        //     return {'current':{data:[], name:monthNames[last_day_current_month.getMonth()]},
        //     'last':{data:[], name:monthNames[last_day_last_month.getMonth()]},'days':Array(31).fill().map((_, i) => i+1)};
        // }

        

        
        
        var last_day = (last_day_current_month.getDate() > last_day_last_month.getDate())?
                        last_day_current_month.getDate():
                        last_day_last_month.getDate();

       
        var last_month = last_day_last_month.getMonth()+1;
        var current_month = last_day_current_month.getMonth()+1;

        var last_year = last_day_last_month.getFullYear();
        var current_year = last_day_current_month.getFullYear();

        var array_last_month = Array(last_day);
        var array_current_month = Array();
        var array_days = Array(last_day);

      


        for (var day =first_day; day <= last_day; day++)
        {
            array_days[day-1] = day;
            var temp = last_year+'-'+this.rellenar(last_month, 2)+'-'+ this.rellenar(day, 2);
            console.log('dia _anterior',temp);
            var data_mes_anterior = data.filter(datex=>!datex._id.localeCompare(temp));
            console.log('dia anterior', data_mes_anterior)

            if (data_mes_anterior.length>0)
            {
                array_last_month[day-1]=data_mes_anterior[0].count;
            }
            else 
            {
                array_last_month[day-1]=0;
            }

            //if (day > new Date().getDate()) continue;

            var temp = current_year+'-'+ this.rellenar(current_month, 2)+'-'+ this.rellenar(day, 2);
            var data_mes_actual= data.filter(datex=>!datex._id.localeCompare(temp));
            
            if (data_mes_actual.length>0)
            {
                array_current_month.push(data_mes_actual[0].count);
                
            }
            else 
            {
                array_current_month.push(0);
            }

        }

        return {'current':{data:array_current_month, name:monthNames[last_day_current_month.getMonth()]},
                'last':{data:array_last_month, name:monthNames[last_day_last_month.getMonth()]},'days':array_days};
        
        
    }

    rellenar(number, len)
    {
        return "0".repeat(len - number.toString().length) + number.toString();
    }
}

export default LeadsPerMonth;