import {parallel} from 'async';
import {Wave} from 'better-react-spinkit';
import {in_array as inArray} from 'locutus/php/array';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// noinspection ES6CheckImport
import {formatDate, parseDate} from 'react-day-picker/moment';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {FormGroup, Label} from 'reactstrap';
import '../../reports.css';
import LeadsAssigned from '../../components/reports/LeadsAssigned';
import LeadsAttended from '../../components/reports/LeadsAttended';
import LeadsPerModel from '../../components/reports/LeadsPerModel';
import LeadsPerStore from '../../components/reports/LeadsPerStore';
import LeadsScheduled from '../../components/reports/LeadsScheduled';
import LeadsUnattended from '../../components/reports/LeadsUnattended';
import RankingPerDealership from '../../components/reports/RankingPerDealership';
import LeadsPerMonth from '../../components/reports/leadPerMonth';
import Constants from '../../lib/Constants';
import Session from '../../lib/Session';
import Utils from '../../lib/Utils';
import Brand from '../../models/Brand';
import Dealership from '../../models/Dealership';
import Store from '../../models/Store';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            brands: [],
            dealerships: [],
            stores: [],
            filter: {
                startDate: '',
                endDate: '',
                brandId: '',
                dealershipId: '',
                storeId: ''
            },
            filter2:{
                date_per_month:''
            }
        };
    }

    componentDidMount() {
        let newState = {
            loading: false
        };
        let tasks = [
            callback => {
                const params = `filter[fields][id]=true&filter[fields][name]=true`;

                Brand.find(params).then(result => {
                    if (result.status) {
                        newState.brands = result.data;
                    }

                    callback(null, true);
                }).catch(() => callback(null, false));
            },
            callback => {
                const params = `filter[where][enabled]=true`;

                Dealership.find(params).then(result => {
                    if (result.status) {
                        newState.dealerships = result.data;
                    }

                    callback(null, true);
                }).catch(() => callback(null, false));
            }
        ];

        const user = Session.getUser();

        if (user.role === Constants.Role.SALES_MANAGER) {
            tasks.push(callback => {
                const params = `filter[where][salesManagerId]=${user.id}`;

                Store.find(params).then(result => {
                    if (result.status) {
                        this.setState({stores: result.data}, () => callback(null, true));
                    } else {
                        callback(null, false);
                    }
                }).catch(() => callback(null, false));
            });
        }

        parallel(tasks, () => this.setState(newState));
    }

    getBrandOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.brands.forEach(brand => {
            options.push({value: brand.id, label: brand.name});
        });

        return options;
    };

    getDealershipOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.dealerships.forEach(dealership => {
            options.push({value: dealership.id, label: dealership.name});
        });

        return options;
    };

    getStoreOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.stores.forEach(store => {
            options.push({value: store.id, label: store.name});
        });

        return options;
    };

    onChangeBrandId = e => this.setState({filter: Object.assign(this.state.filter, {brandId: e.value})});

    onChangeDealershipId = e => this.setState(
        {
            stores: [],
            filter: Object.assign(this.state.filter, {dealershipId: e.value, storeId: null})
        },
        () => {
            let newState = {
                stores: []
            };

            if (Utils.isUsable(this.state.filter.dealershipId)) {
                const params = `filter[where][dealershipId]=${this.state.filter.dealershipId}`;

                Store.find(params).then(result => {
                    if (result.status) {
                        newState.stores = result.data;

                        this.setState(newState);
                    }
                });
            } else {
                this.setState(newState);
            }
        }
    );

    onChangeStoreId = e => this.setState({filter: Object.assign(this.state.filter, {storeId: e.value})});

    onChangeStartDate = day => this.setState({
        filter: Object.assign(this.state.filter, {startDate: Utils.formatDate(day)})
    });

    onChangeEndDate = day => this.setState({
        filter: Object.assign(this.state.filter, {endDate: Utils.formatDate(day)})
    });

    lead_per_month_change = e => this.setState({filter2: Object.assign(this.state.filter2,{date_per_month: e.target.value})})

    renderDealershipDropdown = () => {
        const allowedRoles = [
            Constants.Role.ROOT,
            Constants.Role.ADMIN,
            Constants.Role.COORDINATOR,
            Constants.Role.MAIN_MANAGER,
            Constants.Role.MARKETING_MANAGER,
            Constants.Role.BUSINESS_MANAGER,
            Constants.Role.DEALERSHIP_MANAGER
        ];
        const user = Session.getUser();

        if (inArray(user.role, allowedRoles)) {
            return (
                <div className="InputSelect col-md-2">
                    <FormGroup className="form-group">
                        <Label for="exampleName" className="glyphicon pr-2 mb-0">Concesionario</Label>
                        <Dropdown id="dealershipId"
                                  name="dealershipId"
                                  className={"form-control input-select-report"}
                                  onChange={this.onChangeDealershipId}
                                  options={this.getDealershipOptions()}
                                  placeholder="Selecciona..."
                                  value={this.state.filter.dealershipId}
                        />
                    </FormGroup>
                </div>
            );
        }

        return null;
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content content-notifications">
                <div className="row flex mrgBtm30">
                    <div className="col-sm-11">
                        <div className="flex-between">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="dates-client">INDICADORES</h2>
                                    <div className="subline"/>
                                    <br></br>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                    <br></br>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 mb-4">
                                    <Label for="date_per_month">
                                    <img alt="" src="/img/svg/icon_fechahora.svg" width="30"/>
                                    </Label>
                                    
                                    <input type="month" 
                                        id="date_per_month" 
                                        name="date_per_month" 
                                        className="report-datepicker"
                                        value = {this.state.filter2.date_per_month}
                                        onChange={this.lead_per_month_change}
                                        ></input>
                                   
                                    <br></br>
                                    <br></br>
                                    <LeadsPerMonth filter={this.state.filter2}/>
                                </div> 

                                <div className="row">
                                    <div className="col-md-6">
                                    
                                    </div>
                                </div>

                                <div className="col-md-12 row">
                                    <div className="InputDatepicker col-md-6">
                                        <FormGroup className="form-group">
                                            <Label className="glyphicon pr-2 mb-0" for="void">&nbsp;</Label>
                                            <div className="d-flex align-items-center">
                                                <img alt="" src="/img/svg/icon_fechahora.svg" width="30"/>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <DayPickerInput id="startDate"
                                                                        name="startDate"
                                                                        firstDayOfWeek={1}
                                                                        format="YYYY-MM-DD"
                                                                        formatDate={formatDate}
                                                                        inputProps={{className: 'report-datepicker'}}
                                                                        locale="es"
                                                                        months={Constants.DayPicker.MONTHS}
                                                                        onDayChange={this.onChangeStartDate}
                                                                        parseDate={parseDate}
                                                                        weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                                                                        weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <DayPickerInput id="endDate"
                                                                        name="endDate"
                                                                        firstDayOfWeek={1}
                                                                        format="YYYY-MM-DD"
                                                                        formatDate={formatDate}
                                                                        inputProps={{className: 'report-datepicker'}}
                                                                        locale="es"
                                                                        months={Constants.DayPicker.MONTHS}
                                                                        onDayChange={this.onChangeEndDate}
                                                                        parseDate={parseDate}
                                                                        weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                                                                        weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    {this.renderDealershipDropdown()}
                                    <div className="InputSelect col-md-2">
                                        <FormGroup className="form-group">
                                            <Label for="exampleName" className="glyphicon pr-2 mb-0">Tienda</Label>
                                            <Dropdown id="storeId"
                                                      name="storeId"
                                                      className={"form-control input-select-report"}
                                                      onChange={this.onChangeStoreId}
                                                      options={this.getStoreOptions()}
                                                      placeholder="Selecciona..."
                                                      value={this.state.filter.storeId}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="InputSelect col-md-2">
                                        <FormGroup className="form-group">
                                            <Label className="glyphicon pr-2 mb-0" for="brandId">Marca</Label>
                                            <Dropdown id="brandId"
                                                      name="brandId"
                                                      className={"form-control input-select-report"}
                                                      onChange={this.onChangeBrandId}
                                                      options={this.getBrandOptions()}
                                                      placeholder="Selecciona..."
                                                      value={this.state.filter.brandId}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                

                                
                            </div>
                        </div>
                        <div className="row mt-4">
                            
                             <div className="col-md-6 mb-4">
                                <LeadsPerModel filter={this.state.filter}/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <LeadsAssigned filter={this.state.filter}/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <LeadsAttended filter={this.state.filter}/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <LeadsScheduled filter={this.state.filter}/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <LeadsUnattended filter={this.state.filter}/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <LeadsPerStore filter={this.state.filter}/>
                            </div>                            
                            <div className="col-md-6 mb-4">
                                <RankingPerDealership filter={this.state.filter}/>
                            </div> 
                            

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
