import React from 'react';
import {CustomPaging, FilteringState, IntegratedFiltering, PagingState} from '@devexpress/dx-react-grid';
import {Grid, PagingPanel, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import {Link} from 'react-router-dom';
import {IconContext} from 'react-icons';
import {FaCheckSquare} from 'react-icons/fa';
import _IndexTable  from './_IndexTable';
import User from '../../../../models/User'


export default class UserTable extends _IndexTable {
  
  constructor(props)
  {
    super(props);
   
    this.componentDidMount = this.componentDidMount.bind(this);
  }
 
  getData()
  {
    const params = 'filter[where][enabled]=true&filter[where][deleted]=false'
    User.find(params).then(x => {this.setState({rows: x.data})})
  }

  

  getColumns ()
  {
    return [
      {
        name: 'actions',
        title: 'Acción',
          getCellValue: row =>
              <IconContext.Provider value={{color: '#E30012'}}>
                  {row.coti_code > 0 ? <FaCheckSquare />: null}                 
              </IconContext.Provider>
      },
      {
          name: 'id',
          title: 'ID'
      },
      {
          name: 'name',
          title: 'nombre usuarios',
          getCellValue: row => `${row.firstName} ${row.lastName}` 
      },
      {
          name: 'email',
          title: 'Email usuarios'
      }
    ];
  }

  

  getColumnsWidth()
  {
    return [
      {columnName: 'actions', width: 80},
      {columnName: 'id', width: 240},
      {columnName: 'name', width: 360},
      {columnName: 'email', width: 240}
    ];
  }
   

  render  ()
 { 
 return <React.Fragment>
      <Grid rows={this.state.rows} columns={this.state.columns}>
          <FilteringState/>
          <PagingState currentPage={this.state.currentPage}
                        onCurrentPageChange={this.onCurrentPageChange}
                        onPageSizeChange={this.onPageSizeChange}
                        pageSize={this.state.pageSize}
          />
          <CustomPaging totalCount={this.state.totalCount}/>
          <IntegratedFiltering/>
          <Table/>
          <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
          <TableHeaderRow/>
          <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
          <PagingPanel pageSizes={[20, 50, 100]}/>
      </Grid>
    </React.Fragment>;
  }
}