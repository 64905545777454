import {CustomPaging, FilteringState, IntegratedFiltering, PagingState} from '@devexpress/dx-react-grid';
import {Grid, PagingPanel, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {series} from 'async';
import {CubeGrid, Wave} from 'better-react-spinkit';
import {has, isEmpty, isNil} from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import Pusher from 'pusher-js';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';
// noinspection ES6CheckImport
import {formatDate, parseDate} from 'react-day-picker/moment';
import {DebounceInput} from 'react-debounce-input';
import {IconContext} from 'react-icons';
import {FaSpaceShuttle} from 'react-icons/fa';
import {FormGroup, Label} from 'reactstrap'
import replaceString from 'replace-string';
import Constants from '../../../lib/Constants';
import Alert from '../../../lib/Alert';
import Utils from '../../../lib/Utils';
import Lead from '../../../models/Lead';
import Setting from '../../../models/Setting';
import SystemIssue from '../../../models/SystemIssue';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.pusher = {};
        this.state = {
            initializing: true,
            loading: false,
            resending: false,
            prevParams: '',
            params: '',
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'email',
                    title: 'Correo electrónico'
                },
                {
                    name: 'firstName',
                    title: 'Nombres'
                },
                {
                    name: 'lastName',
                    title: 'Apellidos'
                },
                {
                    name: 'documentNumber',
                    title: 'Nº documento'
                },
                {
                    name: 'phone',
                    title: 'Teléfono fijo'
                },
                {
                    name: 'mobilePhone',
                    title: 'Teléfono móvil'
                },
                {
                    name: 'storeName',
                    title: 'Tienda',
                    getCellValue: row => !isNil(row.store) ? row.store.name : ''
                },
                {
                    name: 'brandName',
                    title: 'Marca',
                    getCellValue: row => !isNil(row.brand) ? row.brand.name : ''
                },
                {
                    name: 'model1Name',
                    title: 'Modelo 1',
                    getCellValue: row => !isNil(row.vehicleModels) && !isEmpty(row.vehicleModels) && has(row.vehicleModels, '[0].name') ? row.vehicleModels[0].name : ''
                },
                {
                    name: 'model2Name',
                    title: 'Modelo 2',
                    getCellValue: row => !isNil(row.vehicleModels) && !isEmpty(row.vehicleModels) && has(row.vehicleModels, '[1].name') ? row.vehicleModels[1].name : ''
                },
                {
                    name: 'model3Name',
                    title: 'Modelo 3',
                    getCellValue: row => !isNil(row.vehicleModels) && !isEmpty(row.vehicleModels) && has(row.vehicleModels, '[2].name') ? row.vehicleModels[2].name : ''
                },
                {
                    name: 'createdAt',
                    title: 'Fecha de creación',
                    getCellValue: row => !isNil(row.createdAt) ? moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss a') : ''
                },
                {
                    name: 'origin',
                    title: 'Origen'
                },
                {
                    name: 'purchaseType',
                    title: 'Tipo de compra'
                }
            ],
            defaultColumnWidths: [
                {columnName: 'id', width: 240},
                {columnName: 'email', width: 360},
                {columnName: 'firstName', width: 240},
                {columnName: 'lastName', width: 240},
                {columnName: 'documentNumber', width: 240},
                {columnName: 'phone', width: 240},
                {columnName: 'mobilePhone', width: 240},
                {columnName: 'storeName', width: 240},
                {columnName: 'brandName', width: 240},
                {columnName: 'model1Name', width: 240},
                {columnName: 'model2Name', width: 240},
                {columnName: 'model3Name', width: 240},
                {columnName: 'createdAt', width: 240},
                {columnName: 'origin', width: 240},
                {columnName: 'purchaseType', width: 180},
                {columnName: 'reason', width: 360}
            ],
            rows: [],
            totalCount: 0,
            pageSize: 100,
            currentPage: 0,
            /* Filters */
            searchText: ''
        };
    }

    componentDidMount() {
        this.pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            forceTLS: true
        });
        let channel = this.pusher.subscribe(Constants.Channels.BACK_OFFICE);

        channel.bind('finished', data => {
            Alert.info(data.message);
            setTimeout(() => window.location.reload(), 2500);
        });

        const params = `filter[where][key]=isResendingLeads`;

        Setting.find(params).then(result => {
            let newStatus = {
                initializing: false
            };
            let nextFn = () => {};

            if (result.status && !isEmpty(result.data) && result.data[0].value) {
                newStatus.resending = true;
            } else {
                newStatus.loading = true;
                nextFn = () => this.load();
            }

            this.setState(newStatus, () => nextFn());
        }).catch(e => {
            console.log(e);
            Alert.error('Error');
        });
    }

    getFilterParams = (count = false) => {
        const parts = [];
        const searchText = this.state.searchText;

        if (searchText) {
            parts.push(`filter[where][or][0][id]=${searchText}&filter[where][or][1][$text][search]=${searchText}`);
        }

        let filterParams = '';

        if (!isEmpty(parts)) {
            filterParams = parts.join('&');

            if (count) {
                filterParams = replaceString(filterParams, 'filter[where]', 'where');
            }
        }

        return filterParams;
    };

    getParams = () => {
        let params = `filter[where][status]=${Constants.Lead.STATUS_FAILED}&filter[include]=brand&filter[include]=store&filter[include]=vehicleModels&filter[order]=createdAt DESC`;
        const filterParams = this.getFilterParams();

        if (filterParams !== '') {
            params += '&' + filterParams;
        }

        params += `&filter[limit]=${this.state.pageSize}&filter[skip]=${this.state.currentPage}`;

        return params;
    };

    getQueryString = () => [this.state.prevParams, this.getParams()].filter(x => Utils.isUsable(x)).join('&');

    load = () => {
        const params = this.getQueryString();

        if (this.state.params !== params) {
            this.setState({loading: true}, () => {
                const newState = {
                    loading: false,
                    params: params,
                    totalCount: 0
                };
                const countParams = [
                    replaceString(this.getQueryString(), 'filter[where]', 'where'),
                    this.getFilterParams(true)
                ].filter(x => Utils.isUsable(x)).join('&');

                Lead.count(countParams).then(countResult => {
                    if (countResult.status) {
                        newState.totalCount = countResult.data.count;
                    }

                    Lead.find(params).then(result => {
                        if (result.status) {
                            newState.rows = result.data;
                        }

                        this.setState(newState);
                    }).catch(() => this.setState(newState));
                }).catch(() => this.setState(newState));
            });
        }
    };

    resend = () => {
        const data = {
            type: Constants.SystemIssue.TYPE_FAILED_LEAD
        };
        const to = `/actions`;

        SystemIssue.post(data, to).then(result => {
            if (result.status) {
                Alert.info(result.data.message);
                this.setState({resending: true})
            } else {
                Alert.error(result.data.message);
            }
        }).catch(e => {
            console.log(e);
            Alert.error('Error');
        });
    };

    onCurrentPageChange = currentPage => this.setState({loading: true, currentPage: currentPage}, () => this.load());

    onChangeSearchText = e => this.setState({searchText: e.target.value}, () => this.load());

    onPageSizeChange = pageSize => this.setState({pageSize: pageSize}, () => this.load());

    onResend = e => {
        e.preventDefault();
        confirmAlert({
            title: `Reenviar leads`,
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.resend()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    renderResendButton = () => {
        if (this.state.initializing || this.state.resending) {
            return null;
        }

        return (
            <a href="/" onClick={this.onResend}>
                <IconContext.Provider value={{color: '#E30012', size: '2.5em'}}>
                    <FaSpaceShuttle/>
                </IconContext.Provider>
            </a>
        );
    };

    renderTable = () => {
        if (this.state.initializing) {
            return null;
        }

        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        if (this.state.resending) {
            return (
                <CubeGrid color="#D81626" size={50}/>
            );
        }

        return (
            <Grid rows={this.state.rows} columns={this.state.columns}>
                <FilteringState/>
                <PagingState currentPage={this.state.currentPage}
                             onCurrentPageChange={this.onCurrentPageChange}
                             onPageSizeChange={this.onPageSizeChange}
                             pageSize={this.state.pageSize}
                />
                <CustomPaging totalCount={this.state.totalCount}/>
                <IntegratedFiltering/>
                <Table/>
                <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                <TableHeaderRow/>
                <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
                <PagingPanel pageSizes={[20, 50, 100]}/>
            </Grid>
        );
    };

    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">LEADS FALLIDOS</h2>
                                <div className="subline"/>
                            </div>
                            <div>
                                {this.renderResendButton()}
                            </div>
                            <div className="InputSearch">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="searchText">
                                            <img alt="" src="/img/svg/icon_buscar.svg" width="20"/>
                                        </Label>
                                        <DebounceInput id="searchText"
                                                       name="searchText"
                                                       className="form-control"
                                                       debounceTimeout={300}
                                                       minLength={2}
                                                       onChange={this.onChangeSearchText}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {this.renderTable()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
