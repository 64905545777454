import BaseAlert from 'react-s-alert';

class Alert {
    static defaultConfig = {
        position: 'top-right',
        effect: 'slide',
        html: true,
        timeout: 1500
    };

    static error = message => BaseAlert.error(message, Alert.defaultConfig);

    static info = message => BaseAlert.info(message, Alert.defaultConfig);
}

export default Alert;
