import React from 'react';
import DealershipForm from '../../../components/forms/DealershipForm';
import Constants from '../../../lib/Constants';

class Create extends React.Component {
    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CREAR CONCESIONARIO</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <DealershipForm mode={Constants.Form.MODE_CREATE}/>
            </div>
        );
    }
}

export default Create;
