import { parallel } from 'async';
import { Wave } from 'better-react-spinkit';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import { isUndefined } from 'lodash';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { IconContext } from 'react-icons';
import { FaWhatsapp,FaRegTimesCircle } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip
} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import Department from '../../models/Department';
import District from '../../models/District';
import Lead from '../../models/Lead';
import VehicleModel from '../../models/VehicleModel';
import validate from 'validate.js';

class RemoveButton extends React.Component {
    onClick = e => {
        e.preventDefault();
        this.props.onClick(this.props.id);
    };

    render() {
        return (
            <IconContext.Provider value={{ color: '#E30012' }}>
                <a href="/" onClick={this.onClick}>
                    <FaRegTimesCircle />
                </a>
            </IconContext.Provider>
        );
    }
}

class VehicleModelModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            options: [],
            vehicleModelId: null
        };
    }

    componentDidMount() {
        let options = [
            { value: '', label: 'Selecciona...' }
        ];
        const models = this.props.models;

        models.forEach(model => options.push({
            value: model.id,
            label: model.name
        }));

        this.setState({ loading: false, options: options });
    }

    onAdd = () => {
        const maxNumModels = Detail.MAX_NUM_MODELS;

        if (this.props.lead.vehicleModelIds.length < maxNumModels) {
            if (this.state.vehicleModelId) {
                const index = this.props.lead.vehicleModelIds.findIndex(x => x === this.state.vehicleModelId);

                if (index >= 0) {
                    Alert.error('Selecciona otro modelo');
                } else {
                    if (isFunction(this.props.addHandler)) {
                        this.props.addHandler(this.state.vehicleModelId);
                    } else {
                        console.error('addHandler prop is not a function');
                    }
                }
            } else {
                Alert.error('Selecciona el modelo');
            }
        } else {
            Alert.error(`No puedes agregar más de ${maxNumModels} modelos`);
        }
    };

    onChangeVehicleModelId = option => this.setState({ vehicleModelId: option.value });

    onRemove = id => {
        if (isFunction(this.props.removeHandler)) {
            this.props.removeHandler(id);
        } else {
            console.error('removeHandler prop is not a function');
        }
    };

    renderDropdown() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50} />
            );
        }

        return (
            <Dropdown onChange={this.onChangeVehicleModelId}
                options={this.state.options}
                placeholder="Modelos"
                value={this.state.vehicleModelId}
            />
        );
    }

    render() {
        let models = [];
        let filtered;

        this.props.lead.vehicleModelIds.forEach(modelId => {
            filtered = this.props.models.filter(x => x.id === modelId);

            if (filtered && filtered[0]) {
                models.push(filtered[0]);
            }
        });

        return (
            <Modal isOpen={this.props.showModal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Agregar modelo</ModalHeader>
                <ModalBody>
                    {this.renderDropdown()}
                    <hr />
                    {models.map(model =>
                        <p key={`model-${model.id}`}>
                            {model.name}
                            {' '}
                            <RemoveButton id={model.id} onClick={this.onRemove} />
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onAdd}>Agregar</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

class Detail extends React.Component {
    static INDEX_ROUTE = '/call-center';
    static MAX_NUM_MODELS = process.env.REACT_APP_MAX_NUM_MODELS_PER_LEAD;
    static PARAMS = 'filter[include]=brand&filter[include]=department&filter[include]=store&filter[include]=vehicleModels';

    constructor(props) {
        super(props);

        this.purchaseTypeOptions = [
            { value: '', label: 'Selecciona...' },
            { value: 'Contado', label: 'Contado' },
            { value: 'Crédito', label: 'Crédito' }
        ];

        this.testDriveOptions = [
            { value: 'No', label: 'No' },
            { value: 'En tienda', label: 'En tienda' },
            { value: 'A domicilio', label: 'A domicilio' }
        ];
        this.valuationOptions = [
            { value: 'No', label: 'No' },
            { value: 'En tienda', label: 'En tienda' },
            { value: 'Virtual', label: 'Virtual' }
        ];
        this.state = {
            loading: true,
            loadingStores: false,
            processing: false,
            model: {},
            vehicleModels: [],
            departments: [],
            chosenDepartmentId: '',
            stores: [],
            formValues: {
                firstName: '',
                lastName: '',
                documentNumber: '',
                phone: '',
                mobilePhone: '',
                email: '',
                purchaseType: '',
                vehicleModelIds: [],
                storeId: '',
                altStore: '',
                comment: '',
                versionExId: '',
                credit: '',
            },
            altStore: '',
            saved: false,
            showModal: false
        };
    }

    componentDidMount() {
        const redirect = () => {
            Alert.error('Lead inválido');
            this.props.history.push(Detail.INDEX_ROUTE);
        };
        const newState = {
            loading: false
        };

        Lead.findById(this.props.match.params.id, Detail.PARAMS).then(result1 => {
            if (result1.status) {
                const model = result1.data;
                newState.model = model;
                newState.formValues = {
                    firstName: model.firstName,
                    lastName: model.lastName,
                    documentNumber: model.documentNumber,
                    phone: model.phone,
                    mobilePhone: model.mobilePhone,
                    email: model.email,
                    purchaseType: model.purchaseType,
                    vehicleModelIds: model.vehicleModelIds,
                    storeId: model.storeId,
                    altStore: model.altStore,
                    comment: model.comment,
                    versionExId: model.versionExId,
                    credit: model.credit,
                    valuation: model.valuation,
                    testDrive: model.testDrive,
                    testDriveDate: model.testDriveDate,
                    testDriveSchedule: model.testDriveSchedule,
                    address: model.address,
                    year: model.year,
                    valuationBrand: model.valuationBrand,
                    valuationModel: model.valuationModel,
                    laborSystem: model.laborSystem,
                    laborStartDate: model.laborStartDate,
                    company: model.company,
                    ruc: model.ruc,
                    monthlyIncome: model.monthlyIncome,
                    wifeDocument: model.wifeDocument,
                    wifeLaborSystem: model.wifeLaborSystem,
                    wifeMonthlyIncome: model.wifeMonthlyIncome,
                    photo1: model.photo1,
                    photo2: model.photo2,
                    photo3: model.photo3,
                    photo4: model.photo4,
                };
                newState.altStore = model.altStore;
                model.testDriveDate = moment(model.testDriveDate, 'YYYY-MM-DD').toDate();
                model.laborStartDate = moment(model.laborStartDate, 'YYYY-MM-DD').toDate();
                if (model.status === Constants.Lead.STATUS_CHECKED) {
                    const tasks = [
                        callback => {
                            const params = `filter[where][brandId]=${model.brandId}`;

                            VehicleModel.find(params).then(result => {
                                if (result.status) {
                                    newState.vehicleModels = result.data;
                                }

                                callback(null, true);
                            }).catch(() => callback(null, false));
                        },
                        callback => {
                            Department.find().then(result => {
                                if (result.status) {
                                    newState.departments = result.data;
                                }

                                callback(null, true);
                            }).catch(() => callback(null, false));
                        },
                        callback => {
                            if (isNil(model.store) || isNil(model.store.districtId)) {
                                callback(null, false);
                            } else {
                                District.findById(model.store.districtId).then(districtResult => {
                                    if (districtResult.status) {
                                        const district = districtResult.data;
                                        const params = `filter[where][name]=${district.department.name}`;

                                        Department.find(params).then(departmentsResult => {
                                            if (departmentsResult.status) {
                                                const department = departmentsResult.data[0];
                                                const to = `/${department.id}/stores`;

                                                Department.get({}, to).then(storesResult => {
                                                    if (storesResult.status) {
                                                        newState.chosenDepartmentId = department.id;
                                                        newState.stores = storesResult.data;
                                                    }

                                                    callback(null, true);
                                                }).catch(() => callback(null, false));
                                            }
                                        }).catch(() => callback(null, false));
                                    }
                                }).catch(() => callback(null, false));
                            }
                            /*
                            if (isNil(newState.formValues.departmentId) || newState.formValues.departmentId === '') {
                                Store.find().then(result => {
                                    if (result.status) {
                                        newState.stores = result.data;
                                    }

                                    callback(null, true);
                                }).catch(() => callback(null, false));
                            } else {
                                const to = `/${newState.formValues.departmentId}/stores`;

                                Department.get({}, to).then(result => {
                                    if (result.status) {
                                        newState.stores = result.data;
                                    }

                                    callback(null, true);
                                }).catch(() => callback(null, false));
                            }
                            */
                        }
                    ];

                    parallel(tasks, () => this.setState(newState));
                } else {
                    redirect();
                }
            } else {
                redirect();
            }
        }).catch(() => {
            Alert.error('Error. Por favor inténtelo de nuevo')
        });
    }

    assign = async () => {
        const data = {
            id: this.state.model.id
        };
        const to = `/assignments`;
        const result = await Lead.post(data, to);

        if (result.status) {
            Alert.info('Lead asignado');
            this.props.history.push(Detail.INDEX_ROUTE);
        } else {
            Alert.error('No se pudo derivar lead');
        }
    };

    discard = async () => {
        const data = {
            id: this.state.model.id
        };
        const to = `/discards`;
        const result = await Lead.post(data, to);

        if (result.status) {
            Alert.info('Lead descartado');
            this.props.history.push(Detail.INDEX_ROUTE);
        } else {
            Alert.error('No se pudo descartar el lead');
        }
    };

    discardWithWhatApp = async() => {
        if (!this.state.model.mobilePhone){Alert.error("Se necesita el celular"); return;}
        if (!this.state.model.firstName){Alert.error("Se necesita los nombres"); return;}
       
        const data = {
            id: this.state.model.id
        };
        const to = `/discardsWithWhatsapp`;
        const result = await Lead.post(data, to);

        if (result.status) {
            Alert.info('Lead descartado y enviado el wsp');
            this.props.history.push(Detail.INDEX_ROUTE);
        } else {
            Alert.error(result.error.message);
        }
    }

    getDepartmentOptions = () => {
        let options = [
            { value: '', label: 'Selecciona...' }
        ];

        this.state.departments.forEach(department => {
            options.push({ value: department.id, label: department.name });
        });

        return options;
    };

    getStoreOptions = () => {
        let options = [
            { value: '', label: 'Selecciona...' }
        ];

        this.state.stores.forEach(store => {
            options.push({ value: store.id, label: store.name });
        });

        return options;
    };

    getVehicleModelNames = () => {
        const vehicleModels = this.state.model.vehicleModels;
        let names = [];

        if (isArray(vehicleModels)) {
            names = vehicleModels.map(vehicleModel => vehicleModel.name);
        }

        return names.join(', ');
    };

    onAddModel = id => {
        let model = clone(this.state.model);
        let filtered = this.state.vehicleModels.filter(x => x.id === id);

        if (filtered.length) {
            model.vehicleModelIds.push(id);
            model.vehicleModels.push(filtered[0]);

            this.setState({
                model: model,
                formValues: Object.assign(this.state.formValues, { vehicleModelIds: model.vehicleModelIds })
            });
        }
    };

    onDiscard = () => {
        confirmAlert({
            title: 'Descartar lead',
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.discard()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    onDiscardWithWhatsApp = ()=>{
        confirmAlert({
            title: 'Descartar lead y Enviar WhatsApp',
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.discardWithWhatApp()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        })
    }

    onAssign = () => {
        if (this.state.saved) {
            confirmAlert({
                title: 'Derivar lead',
                message: '¿Estás segur@?',
                buttons: [
                    {
                        label: 'Sí',
                        onClick: () => this.assign()
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            Alert.error('Guarde los cambios antes de continuar');
        }
    };

    onChangeComment = e => this.setState({
        formValues: Object.assign(this.state.formValues, { comment: e.target.value })
    });

    onChangeDepartmentId = option => {
        if (option.value) {
            this.setState({
                loadingStores: true,
                processing: true,
                chosenDepartment: option.value,
                formValues: Object.assign(this.state.formValues, { departmentId: option.value, storeId: null })
            }, () => {
                const newState = {
                    loadingStores: false,
                    processing: false
                };
                const to = `/${this.state.formValues.departmentId}/stores`;

                Department.get({}, to).then(result => {
                    if (result.status) {
                        newState.stores = result.data;
                    }
                }).finally(() => this.setState(newState));
            });
        } else {
            this.setState({
                chosenDepartment: '',
                formValues: Object.assign(this.state.formValues, { departmentId: null, storeId: null })
            });
        }
    };

    onChangeDocumentNumber = e => this.setState({
        formValues: Object.assign(this.state.formValues, { documentNumber: e.target.value })
    });

    onChangeEmail = e => this.setState({
        formValues: Object.assign(this.state.formValues, { email: e.target.value })
    });

    onChangeFirstName = e => this.setState({
        formValues: Object.assign(this.state.formValues, { firstName: e.target.value })
    });

    onChangeLastName = e => this.setState({
        formValues: Object.assign(this.state.formValues, { lastName: e.target.value })
    });

    onChangeMobilePhone = e => this.setState({
        formValues: Object.assign(this.state.formValues, { mobilePhone: e.target.value })
    });

    onChangePhone = e => this.setState({
        formValues: Object.assign(this.state.formValues, { phone: e.target.value })
    });

    onChangePurchaseType = option => this.setState({
        formValues: Object.assign(this.state.formValues, { purchaseType: option.value })
    });

    onChangeStoreId = option => {
        let newState = {
            formValues: Object.assign(this.state.formValues, { storeId: option.value })
        };

        if (isNil(newState.formValues.storeId) || newState.formValues.storeId === '') {
            newState.formValues.altStore = this.state.altStore;
        } else {
            newState.formValues.altStore = null;
        }

        this.setState(newState);
    };

    onChangeVersion = e => this.setState({
        formValues: Object.assign(this.state.formValues, { versionExId: e.target.value })
    });

    onChangeLaborSystem = e => this.setState({
        formValues: Object.assign(this.state.formValues, { laborSystem: e.target.value })
    });

    onChangeCompany = e => this.setState({
        formValues: Object.assign(this.state.formValues, { company: e.target.value })
    });

    onChangeMonthlyIncome = e => this.setState({
        formValues: Object.assign(this.state.formValues, { monthlyIncome: e.target.value })
    });

    onChangeWifeLaborSystem = e => this.setState({
        formValues: Object.assign(this.state.formValues, { wifeLaborSystem: e.target.value })
    });

    onChangeTestDrive = option => this.setState({
        formValues: Object.assign(this.state.formValues, { testDrive: option.value })
    });

    onChangeValutation = option => this.setState({
        formValues: Object.assign(this.state.formValues, { valuation: option.value })
    });

    onChangeYear = e => this.setState({
        formValues: Object.assign(this.state.formValues, { year: e.target.value })
    });

    onChangeValuationModel = e => this.setState({
        formValues: Object.assign(this.state.formValues, { valuationModel: e.target.value })
    });

    onChangeValuationBrand = e => this.setState({
        formValues: Object.assign(this.state.formValues, { valuationBrand: e.target.value })
    });

    onChangeLaborStartDate = day => {
        formValues: Object.assign(this.state.formValues, { laborStartDate: !isUndefined(day) ? moment(day).format('YYYY-MM-DD') : null })
    }

    onChangeRUC = e => this.setState({
        formValues: Object.assign(this.state.formValues, { ruc: e.target.value })
    });

    onChangeWifeDocument = e => this.setState({
        formValues: Object.assign(this.state.formValues, { wifeDocument: e.target.value })
    });

    onChangeWifeMonthlyIncome = e => this.setState({
        formValues: Object.assign(this.state.formValues, { wifeMonthlyIncome: e.target.value })
    });

    onChangeAddress = e => this.setState({
        formValues: Object.assign(this.state.formValues, { address: e.target.value })
    });

    onChangeTestDriveSchedule = e => this.setState({
        formValues: Object.assign(this.state.formValues, { testDriveSchedule: e.target.value })
    });

    onChangeTestDriveDate = day => {
        formValues: Object.assign(this.state.formValues, { testDriveDate: !isUndefined(day) ? moment(day).format('YYYY-MM-DD') : null });
    }

    onRemoveModel = id => {
        let model = clone(this.state.model);
        model.vehicleModels = remove(model.vehicleModels, x => x.id !== id);
        model.vehicleModelIds = remove(model.vehicleModelIds, x => x !== id);

        this.setState({
            model: model,
            formValues: Object.assign(this.state.formValues, { vehicleModelIds: model.vehicleModelIds })
        });
    };

    onSave = () => {
        const constraints = {
            firstName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los nombres'
                }
            },
            lastName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los apellidos'
                }
            },
            documentNumber: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el Nº de documento'
                }
            },
            mobilePhone: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el teléfono móvil'
                }
            },
            email: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el correo electrónico'
                },
                email: {
                    message: 'El correo electrónico es inválido'
                }
            },
            purchaseType: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el tipo de compra'
                }
            },
            vehicleModelIds: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona uno o más modelos'
                }
            },
            storeId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la tienda'
                }
            }
        };
        const validationResult = validate(this.state.formValues, constraints, { format: 'flat', fullMessages: false });

        if (isArray(validationResult)) {
            Alert.error(validationResult.join('<br>'));
        } else {
            this.setState({ processing: true }, () => {
                let newState = {
                    processing: false
                };

                Lead.update(this.state.model.id, this.state.formValues).then(result1 => {
                    if (result1.status) {
                        Lead.findById(this.state.model.id, Detail.PARAMS).then(result2 => {
                            newState.model = result2.data;
                            newState.saved = true;

                            Alert.info('Cambios guardados');
                            this.setState(newState);
                        }).catch(e => {
                            console.log(e);
                            Alert.error('Error');
                            this.setState(newState);
                        });
                    } else {
                        Alert.error('Error. No se pudieron guardar los cambios');
                        this.setState(newState);
                    }
                }).catch(e => {
                    console.log(e);
                    Alert.error('Error');
                    this.setState(newState);
                });
            });
        }
    };

    onShowModal = () => this.toggle();

    renderAltSymbol = () => {
        const altStore = this.state.formValues.altStore;

        if (!isNil(altStore) && altStore !== '') {
            return (
                <span>
                    <img id="altStoreInfo"
                        alt=""
                        className="img-important"
                        src="/img/excla.png"
                        width="25"
                    />
                    <UncontrolledTooltip placement="top" target="altStoreInfo">
                        {this.state.formValues.altStore}
                    </UncontrolledTooltip>
                </span>
            );
        }

        return null;
    };

    renderButtons = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25} />
            );
        }

        return (
            <div className="flex flex-btns">
                <Button className="btn-save" onClick={this.onDiscardWithWhatsApp}> <FaWhatsapp/> ENVIAR WSP</Button>
                <Button className="btn-save" onClick={this.onDiscard}>DESCARTAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>GUARDAR CAMBIOS</Button>
                <Button className="btn-derive" onClick={this.onAssign}>DERIVAR</Button>
            </div>
        );
    };

    renderStoreDropdown = () => {
        if (this.state.loadingStores) {
            return <Wave color="#D81626" size={50} />;
        }

        return (
            <FormGroup className="form-group">
                <div className="icon-addon addon-lg position-relative">
                    <Label className="glyphicon" for="storeId" title="Tienda">
                        <img alt="" src="/img/svg/icon_tienda.svg" width="20" />
                    </Label>
                    <Dropdown id="storeId"
                        name="storeId"
                        className={"form-control form-control-dropdown"}
                        onChange={this.onChangeStoreId}
                        options={this.getStoreOptions()}
                        placeholder="Tienda"
                        value={this.state.formValues.storeId}
                    />
                    {this.renderAltSymbol()}
                </div>
            </FormGroup>
        );
    };

    toggle = () => this.setState(prevState => ({ showModal: !prevState.showModal }));

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50} />
            );
        }

        const model = this.state.model;

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">DATOS DEL CLIENTE</h2>
                                <div className="subline" />
                            </div>
                            <div>
                                <div className="id_text">ID: {model.id}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="firstName" title="Nombre">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20" />
                                            </Label>
                                            <Input id="firstName"
                                                name="firstName"
                                                onChange={this.onChangeFirstName}
                                                placeholder="Nombre"
                                                type="text"
                                                value={this.state.formValues.firstName}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="lastName" title="Apellido">
                                                <img alt="" src="/img/svg/icon_nombre.svg" width="20" />
                                            </Label>
                                            <Input id="lastName"
                                                name="lastName"
                                                onChange={this.onChangeLastName}
                                                placeholder="Apellidos"
                                                type="text"
                                                value={this.state.formValues.lastName}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="documentNumber" title="N° de documento">
                                                <img alt="" src="/img/svg/icon_dni.svg" width="20" />
                                            </Label>
                                            <Input id="documentNumber"
                                                name="documentNumber"
                                                onChange={this.onChangeDocumentNumber}
                                                placeholder="Nª de documento"
                                                type="text"
                                                value={this.state.formValues.documentNumber}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="phone" title="Teléfono fijo">
                                                <img alt="" src="/img/svg/icon_phone.svg" width="20" />
                                            </Label>
                                            <Input id="phone"
                                                name="phone"
                                                onChange={this.onChangePhone}
                                                placeholder="Teléfono fijo"
                                                type="text"
                                                value={this.state.formValues.phone}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="mobilePhone" title="Teléfono móvil">
                                                <img alt="" src="/img/svg/icon_telephone.svg" width="20" />
                                            </Label>
                                            <Input id="mobilePhone"
                                                name="mobilePhone"
                                                onChange={this.onChangeMobilePhone}
                                                placeholder="Teléfono móvil"
                                                type="text"
                                                value={this.state.formValues.mobilePhone}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="email" title="Correo">
                                                <img alt="" src="/img/svg/icon_email.svg" width="20" />
                                            </Label>
                                            <Input id="email"
                                                name="email"
                                                onChange={this.onChangeEmail}
                                                placeholder="Correo electrónico"
                                                type="text"
                                                value={this.state.formValues.email}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="version" title="Versión">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="version"
                                                name="versionExId"
                                                onChange={this.onChangeVersion}
                                                placeholder="Versión"
                                                type="text"
                                                value={this.state.formValues.versionExId}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="laborSystem" title="Régimen laboral">
                                                <img alt="" src="/img/svg/icon_regimenlaboral.svg" width="20" />
                                            </Label>
                                            <Input id="laborSystem"
                                                name="laborSystem"
                                                onChange={this.onChangeLaborSystem}
                                                placeholder="Régimen laboral"
                                                type="text"
                                                value={this.state.formValues.laborSystem}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="company" title="Empresa">
                                                <img alt="" src="/img/svg/icon_regimenlaboral.svg" width="20" />
                                            </Label>
                                            <Input id="company"
                                                name="company"
                                                onChange={this.onChangeCompany}
                                                placeholder="Empresa"
                                                type="text"
                                                value={this.state.formValues.company}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="monthlyIncome" title="Ingreso mensual">
                                                <img alt="" src="/img/svg/icon_ingresomensual.svg" width="20" />
                                            </Label>
                                            <Input id="monthlyIncome"
                                                name="monthlyIncome"
                                                onChange={this.onChangeMonthlyIncome}
                                                placeholder="Ingreso Mensual"
                                                type="text"
                                                value={this.state.formValues.monthlyIncome}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="wifeDocument" title="DNI de cónyuge">
                                                <img alt="" src="/img/svg/icon_dni.svg" width="20" />
                                            </Label>
                                            <Input id="wifeDocument"
                                                name="wifeDocument"
                                                onChange={this.onChangeWifeDocument}
                                                placeholder="DNI Cónyuge"
                                                type="text"
                                                value={this.state.formValues.wifeDocument}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="testDrive" title="Test drive">
                                                <img alt="" src="/img/svg/icon_testdrive.svg" width="20" />
                                            </Label>
                                            <Dropdown id="testDrive"
                                                name="testDrive"
                                                className={"form-control form-control-dropdown"}
                                                onChange={this.onChangeTestDrive}
                                                options={this.testDriveOptions}
                                                placeholder="Test drive"
                                                value={this.state.formValues.testDrive}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="testDriveDate" title="Fecha de Test drive">
                                                <img alt="" src="/img/svg/icon_fecha.svg" width="20" />
                                            </Label>
                                            <div className="form-control">
                                                <DayPickerInput id="testDriveDate"
                                                    name="testDriveDate"
                                                    inputProps={{ className: 'DayPicker-control' }}
                                                    firstDayOfWeek={1}
                                                    format="YYYY-MM-DD"
                                                    formatDate={formatDate}
                                                    locale="es"
                                                    months={Constants.DayPicker.MONTHS}
                                                    onDayChange={this.onChangeTestDriveDate}
                                                    parseDate={parseDate}
                                                    placeholder="Fecha Test Drive"
                                                    weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                                                    weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                                                    value={model.testDriveDate}
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="valuation" title="Tasación">
                                                <img alt="" src="/img/svg/icon_tasacion.svg" width="20" />
                                            </Label>
                                            <Dropdown id="valuation"
                                                name="valuation"
                                                className={"form-control form-control-dropdown"}
                                                onChange={this.onChangeValutation}
                                                options={this.valuationOptions}
                                                placeholder="Tasación"
                                                value={this.state.formValues.valuation}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="valuationModel" title="Modelo en tasación">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="valuationModel"
                                                name="valuationModel"
                                                onChange={this.onChangeValuationModel}
                                                placeholder="Modelo en tasación"
                                                type="text"
                                                value={this.state.formValues.valuationModel}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="purchaseType" title="Tipo de compra">
                                                <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20" />
                                            </Label>
                                            <Dropdown id="purchaseType"
                                                name="purchaseType"
                                                className={"form-control form-control-dropdown"}
                                                onChange={this.onChangePurchaseType}
                                                options={this.purchaseTypeOptions}
                                                placeholder="Tipo de compra"
                                                value={this.state.formValues.purchaseType}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="brandName" title="Marca">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="brandName"
                                                name="brandName"
                                                placeholder="Marca"
                                                readOnly={true}
                                                type="text"
                                                value={model.brand.name}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg position-relative">
                                            <Label className="glyphicon" for="vehicleModelNames" title="Modelos">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="vehicleModelNames"
                                                name="vehicleModelNames"
                                                placeholder="Modelos"
                                                readOnly={true}
                                                type="text"
                                                value={this.getVehicleModelNames()}
                                            />
                                            <img alt=""
                                                className="img-more-model"
                                                onClick={this.onShowModal}
                                                src="/img/svg/icon_mas.svg" width="20"
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="departmentId" title="Departamento">
                                                <img alt="" src="/img/svg/icon_departamentos.svg" width="20" />
                                            </Label>
                                            <Dropdown id="departmentId"
                                                name="departmentId"
                                                className={"form-control form-control-dropdown"}
                                                onChange={this.onChangeDepartmentId}
                                                options={this.getDepartmentOptions()}
                                                placeholder="Departamento"
                                                value={this.state.chosenDepartmentId}
                                            />
                                        </div>
                                    </FormGroup>
                                    {this.renderStoreDropdown()}
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="comment" title="Comentario">
                                                <img alt="" src="/img/svg/icon_comentario.svg" width="20" />
                                            </Label>
                                            <Input id="comment"
                                                name="comment"
                                                onChange={this.onChangeComment}
                                                placeholder="Comentarios"
                                                type="text"
                                                value={this.state.formValues.comment}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="credit" title="Crédito Pre-Aprobado Santander">
                                                <img alt="" src="/img/svg/icon_credito.svg" width="20" />
                                            </Label>
                                            <Input id="credit"
                                                name="credit"
                                                placeholder="Crédito Pre-Aprobado Santander"
                                                type="text"
                                                readOnly={true}
                                                value={this.state.formValues.credit}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="laborStartDate" title="Fecha de inicio de labores">
                                                <img alt="" src="/img/svg/icon_fechalaboral.svg" width="20" />
                                            </Label>
                                            <div className="form-control">
                                                <DayPickerInput id="laborStartDate"
                                                    name="laborStartDate"
                                                    inputProps={{ className: 'DayPicker-control' }}
                                                    firstDayOfWeek={1}
                                                    format="YYYY-MM-DD"
                                                    formatDate={formatDate}
                                                    locale="es"
                                                    months={Constants.DayPicker.MONTHS}
                                                    onDayChange={this.onChangeLaborStartDate}
                                                    parseDate={parseDate}
                                                    placeholder="Fecha de inicio de labores"
                                                    weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                                                    weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                                                    value={model.laborStartDate}
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="ruc" title="RUC">
                                                <img alt="" src="/img/svg/icon_ruc.svg" width="20" />
                                            </Label>
                                            <Input id="ruc"
                                                name="ruc"
                                                onChange={this.onChangeRUC}
                                                placeholder="RUC"
                                                type="text"
                                                value={this.state.formValues.ruc}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="wifeLaborSystem" title="Régimen laboral de cónyuge">
                                                <img alt="" src="/img/svg/icon_regimenlaboral.svg" width="20" />
                                            </Label>
                                            <Input id="wifeLaborSystem"
                                                name="wifeLaborSystem"
                                                onChange={this.onChangeWifeLaborSystem}
                                                placeholder="Régimen Laboral Cónyuge"
                                                type="text"
                                                value={this.state.formValues.wifeLaborSystem}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="wifeMonthlyIncome" title="Ingreso mensual de cónyuge">
                                                <img alt="" src="/img/svg/icon_ingresomensual.svg" width="20" />
                                            </Label>
                                            <Input id="wifeMonthlyIncome"
                                                name="wifeMonthlyIncome"
                                                onChange={this.onChangeWifeMonthlyIncome}
                                                placeholder="Ingreso Mensual Cónyuge"
                                                type="text"
                                                value={this.state.formValues.wifeMonthlyIncome}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="address" title="Dirección">
                                                <img alt="" src="/img/svg/icon_direccion.svg" width="20" />
                                            </Label>
                                            <Input id="address"
                                                name="address"
                                                onChange={this.onChangeAddress}
                                                placeholder="Dirección"
                                                type="text"
                                                value={this.state.formValues.address}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="testDriveSchedule" title="Horario de Test drive">
                                                <img alt="" src="/img/svg/icon_hora.svg" width="20" />
                                            </Label>
                                            <Input id="testDriveSchedule"
                                                name="testDriveSchedule"
                                                onChange={this.onChangeTestDriveSchedule}
                                                placeholder="Horario de Test Drive"
                                                type="text"
                                                value={this.state.formValues.testDriveSchedule}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="valuationBrand" title="Marca en tasación">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="valuationBrand"
                                                name="valuationBrand"
                                                onChange={this.onChangeValuationBrand}
                                                placeholder="Marca en tasación"
                                                type="text"
                                                value={this.state.formValues.valuationBrand}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="icon-addon addon-lg">
                                            <Label className="glyphicon" for="year" title="Año">
                                                <img alt="" src="/img/svg/icon_modelos.svg" width="20" />
                                            </Label>
                                            <Input id="year"
                                                name="year"
                                                onChange={this.onChangeYear}
                                                placeholder="Año"
                                                type="text"
                                                value={this.state.formValues.year}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <img alt="" className="img-fluid" src={this.state.formValues.photo1} />
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <img alt="" className="img-fluid" src={this.state.formValues.photo3} />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup className="form-group">
                                        <img alt="" className="img-fluid" src={this.state.formValues.photo2} />
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <img alt="" className="img-fluid" src={this.state.formValues.photo4} />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-2 pad0">
                            {this.renderButtons()}
                        </div>
                    </div>
                </Form>
                <VehicleModelModal addHandler={this.onAddModel}
                    lead={this.state.model}
                    models={this.state.vehicleModels}
                    removeHandler={this.onRemoveModel}
                    showModal={this.state.showModal}
                    toggle={this.toggle}
                />
            </div>
        );
    }
}

export default withRouter(Detail);
