import React from 'react';

import { FormGroup, Input, Label} from 'reactstrap';

import DealershipTable from './Tables/DealershipTable'
import StoreTable from './Tables/StoreTable'
import UserTable from './Tables/UserTable'
import ModelTable from './Tables/ModelTable'


import {FaSyncAlt} from 'react-icons/fa';

import Dealership from '../../../models/Dealership'
import Alert from '../../../lib/Alert';

//import { useLocation } from "react-router-dom";
class Index extends React.Component
{
  constructor(props)
  {
    
    super(props);
    this.state = 
    {
      page_coti: '1',
    }
     
    
    
  }

  componentDidMount() 
  {
    let query = this.getQueryParameter();
    this.setQueryParamters(query); 
  }

  getQueryParameter()
  {
   var query = new URLSearchParams(this.props.location.search);
   return {
     id_page:query.get('page') || '1'
   }
   
  }

  setQueryParamters(query)
  {
    this.props.history.push(`${window.location.pathname}?page=${query.id_page}`)
    console.log("tabla numero ",query.id_page)
    this.setState({
      page_coti: query.id_page
    })
     
  }

  onChangePageCoti = e => {
    
    this.setState({
      page_coti: e.target.value
    })

    this.setQueryParamters({id_page:e.target.value})
 
  };

  reloadAllCotizadorModules()
  {
    document.body.style.cursor = 'wait';
    Dealership.refreshAllCotiCodes({})
      .then(x => {
        document.body.style.cursor = 'default'
        Alert.info("Todo se cagó con éxito");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
        
      })
      .catch(e=> {
        document.body.style.cursor = 'default'
        Alert.error("Error en la actualización de datos");})
  }

  renderCoti(page_coti) {
      switch (page_coti) {
        case '1':
          return <DealershipTable />;
        case '2':
          return <StoreTable />;
        case '3':
          return <UserTable />;
        case '4':
          return <ModelTable />;
      }
  }
  render()
  {
    return (
      <div className="container content" id="cotizador">
      <div className="row flex mrgBtm30">
          <div className="col-sm-12">
              <div className="flex-between">
                  <div>
                      <h2 className="dates-client">COTIZADOR</h2>
                      <div className="subline"/>
                  </div>
                  <div className="InputSearch">
                    <FormGroup className="form-group">
                        <div className="icon-addon addon-lg">
                            <Label className="glyphicon" for="enabled" >
                                <FaSyncAlt onClick={this.reloadAllCotizadorModules}></FaSyncAlt>
                            </Label>
                            <Input id="enabled"
                                    name="enabled"
                                    onChange={this.onChangePageCoti}
                                    type="select"
                                    value={this.state.page_coti}
                            >
                                <option value="1">Consesionaria</option>
                                <option value="2">Tienda</option>
                                <option value="3">Usuarios</option>
                                <option value="4">Modelos</option>
                            </Input>
                        </div>
                    </FormGroup>
                  </div>
              </div>
              {/* <Link to="/cotizador/consesionaria">consesionaria</Link>
              <Link to="/cotizador/tienda">tienda</Link>
              <Link to="/cotizador/usuario">usuario</Link>
              <Link to="/cotizador/modelo">modelo</Link> */}
              
              
              <div className="row">
                  <div className="col-md-12">

                  {this.renderCoti(this.state.page_coti)}
                  </div>
              </div>
          </div>
      </div>
  </div>
    ) 
      
    
  }
}

export default Index; 