import Api from '../lib/models/Api';

class Store extends Api {
    static endpoint = '/stores';

    static changeCotiName (id, coti_name)
    {
        return this.update(id + '/cotiName',{coti_name:coti_name})
    }
}

export default Store;
